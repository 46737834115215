
<div class="widget-percentage-level">

  <div *ngIf="markerPosition === 'TOP'">
    markerPosition {{ markerPosition }}
    <br />
  </div>

  <div *ngIf="markerPosition === 'CENTER'">
    <div class="widget--wrapper">
      <div class="row--progress-bar">
        <div class="progress-bar--bg" [ngStyle]="bgStyle"></div>
        <div class="progress-bar--value" [ngStyle]="valueStyle">
          <span class="marker marker-current-value" [ngStyle]="currentMarkerStyle">
            {{ valueMarker | number: '1.0-0' }} %
          </span>
        </div>
      </div>
    </div>
  </div>

  
  <div *ngIf="markerPosition === 'BOTTOM'">
    <div class="widget--wrapper">
      <div class="row--progress-bar">
        <div class="progress-bar--bg" [ngStyle]="bgStyle"></div>
        <div class="progress-bar--value" [ngStyle]="valueStyle">
        </div>
      </div>
      <div class="row--progress-marker">
        <span class="marker marker-current-value" *ngIf="valueMarker < 95" [ngStyle]="currentMarkerStyle"> {{ valueMarker | number: '1.0-0' }} % </span>
        <span class="marker marker-max-value" *ngIf="valueMarker > 95"> 100% </span>
      </div>
    </div>
  </div>


</div>
