import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CountriesService {
  public apiKey = 'b3BMSzBoamRhVGNtZk5FME5hcGhVNkpkMm1XS1Jib1pxajg2MThaeQ==';

  constructor(private $http: HttpClient) {}

  async getCountriesList() {
    const countries = await this.$http.get<any>(`${environment.server}/countries`).toPromise();
    const rawSort = [
      ...countries.sort((a, b) => {
        return a.name.localeCompare(b.name, 'en', { sensitivity: 'base' });
      }),
    ];
    const rawSortUnique = rawSort.filter((v, i, a) => a.indexOf(v) === i);
    return rawSortUnique;
  }

  async getDepartmentsByCountryName(countryCode: string) {
    const departments = await this.$http.get<any>(`${environment.server}/countries/${countryCode}/states`).toPromise();
    return [
      ...departments.sort((a, b) => {
        return a.name.localeCompare(b.name, 'en', { sensitivity: 'base' });
      }),
    ];
  }

  async getCitiesByCountryAndDepartment(countryCode: string, departmentCode: string) {
    const cities = (await this.$http.get<any>(`${environment.server}/countries/${countryCode}/states/${departmentCode}/cities`).toPromise()).filter(
      (city) => city
    );
    const rawSort = [
      ...cities.sort((a, b) => {
        return a.name.localeCompare(b.name, 'en', { sensitivity: 'base' });
      }),
    ];
    const rawSortUnique = rawSort.filter((v, i, a) => a.indexOf(v) === i);
    return rawSortUnique;
  }
}
