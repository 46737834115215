<div class="page-about page-about-us">
  <section class="section-title">
    <h1>POLÍTICAS DE PRIVACIDAD</h1>
  </section>

  <section class="section-pink">
    <br />
    <br />
    <br />
    <p>
      Fundación SOLIUN SAS, entidad sin ánimo de lucro, inscrita en la Cámara de Comercio de Bogotá el XXX, de las Entidades sin ánimo de lucro, identificada
      con NIT No. XXXXX, enfocada en el diseño, ejecución y evaluación de proyectos de desarrollo sostenible para la niñez, orientada a comunidades en estado de
      vulnerabilidad, está comprometida con la adopción y divulgación de una Política que enmarque la Protección de la información que en virtud de su objeto
      social se encuentre registrada en bases de datos y, como responsable del tratamiento de los mismos, ha diseñado para el efecto el procedimiento para la
      recolección, almacenamiento, uso, circulación y supresión de toda la información debidamente obtenida a través de los diferentes canales de recolección
      (canales virtuales, campañas de mercadeo y comunicación, alianzas programáticas, procedimientos contractuales, manejo de proveedores y en especial,
      ejecución de proyectos).
    </p>
    <br />
    <p>
      El alcance de la presente Política se extiende a los diferentes actores que componen la cadena de recaudación de aportes y desarrollo de las actividades
      complementarias y afines con la actividad principal de Fundación Plan y genera un carácter vinculante sobre todas y todos los funcionarios de la misma,
      así como sobre las organizaciones nacionales e internacionales con que Fundación Plan tenga relación.
    </p>

    <h2>II. MARCO CONSTITUCIONAL</h2>
    <p>
      En desarrollo del derecho fundamental y constitucionalmente reconocido de las personas a conocer, actualizar y rectificar información personal en bases de
      datos, la Constitución Política regula en su artículo 15 la consagración de la “recolección, tratamiento y circulación de datos se respetarán la libertad
      y demás garantías”, de los demás tales como la intimidad, el buen nombre y el hábeas data, entre otros, relacionados con el tratamiento de los datos
      personales.
    </p>
    <br />
    <p>
      Es de tenerse en cuenta que del derecho al hábeas data no solamente se desprenden las facultades ya citadas de conocimiento, actualización y rectificación
      de información que le asisten a los titulares estos derechos, sino también las garantías de autorizar el tratamiento, como incluir nuevos datos,
      excluirlos de las bases de datos que los contengan y por lo tanto, si bien la presente Política se ajusta a la Carta, en el marco regulatorio del Hábeas
      Data en Colombia se ha venido consolidando poco a poco a partir de la incorporación de su reconocimiento en la Constitución de 1991, en cuatro fases
      claramente identificadas.
    </p>

    <br />
    <p>
      Primero, la jurisprudencia nacional, por sobre todo la surgida del órgano de cierre constitucional como lo es la Corte Constitucional. Luego, una segunda
      fase con la aparición de normas especiales, la Ley 1266 de 2008 para información financiera, la que surge de la mano de normativa penal de los delitos
      informáticos consagrados en la Ley 1273 de 2009. Una tercera fase con el desarrollo de la normativa general de datos personales de personas naturales con
      la Ley 1581 de 2012. Y una cuarta fase de reglamentación administrativa, con la puesta en funcionamiento del sistema de registro de bases de datos
      personales o registro de ficheros (RNBD) ante la Superintendencia de Industria y Comercio.
    </p>
  </section>

  <section class="section-green">
    <br />
    <h2>III. MARCO LEGAL</h2>
    <p>
      Fundación como responsable del tratamiento de datos, debe garantizar la protección y debido cuidado en las facultades y garantías que gozan los titulares
      de la información a la cual accede, por lo se rige para el desarrollo de la presente Política por lo preceptuado en el Decreto 1377 de 2013, reglamentario
      de la Ley 1581 de 2012, adoptando para ello el presente documento que contiene no solamente las disposiciones generales sobre la administración de la
      información, sino el procedimiento mediante el cual se llevará a cabo su tratamiento y, los diferentes escenarios o etapas en que éste se surtirá, desde
      la forma en que el titular de la información debe autorizar el acceso a la misma, su circulación y destinación, hasta los medios con que cuenta para
      reportar abusos o indebido manejo de las bases de datos en la cuales reposa su información personal, tales como pérdida, mal uso, alteración, acceso no
      autorizado y delitos informáticos a que está expuesto este recurso.
    </p>
    <br />
    <h2>IV. OBJETO DE LA POLITICA</h2>
    <p>
      Fundación, en su calidad de responsable de la información, encuentra dentro de los deberes legales asociados a la misma, la adopción de una Política que
      responda y actualice con las recientes disposiciones sobre la materia, la protección de los derechos y garantías constitucionales relacionados con el
      hábeas data, a fin de implementar un procedimiento enmarcado en la ley y que regule en debida forma la recolección, tratamiento y circulación de aquella
      información susceptible de ser protegida y que se encuentre contenida en los archivos o bases de datos de la Fundación.
    </p>

    <br />
    <h2>V. DEBERES DE LOS RESPONSABLES DEL TRATAMIENTO DE LA INFORMACIÓN</h2>
    <p>
      Fundación , en atención del artículo 17 de la Ley 1581 de 2012, el cual contempla los deberes como responsable del tratamiento de la información
      administrada en sus bases de datos, se compromete a garantizar de forma plena y reglada el efectivo ejercicio de los derechos fundamentales que le asisten
      a los titulares de la información, aplicando para el caso, la presente Política que abarque los procedimientos que permitan cumplir con el objetivo de la
      misma.
    </p>

    <br />
    <p>
      Por lo anterior, todos y cada uno de los deberes contenidos en la mencionada regulación, serán desarrollados en el presente documento, para el cabal
      entendimiento del público en general.
    </p>
  </section>

  <section class="section-pink">
    <br />
    <h2>VI. DEFINICIONES</h2>
    <p>
      Con el fin de brindar una mejor comprensión de los términos que se aplicarán en la presente Política, los siguientes son conceptos clave a tener en
      cuenta:
    </p>
    <br />
    <h2>AVISO DE PRIVACIDAD</h2>
    <p>
      Comunicación verbal o escrita emitida por La Fundación, dirigida al Titular para el Tratamiento de sus datos personales, mediante la cual se le informa la
      Políticas de Tratamiento de información aplicable, la forma de acceder a las mismas y las finalidades del Tratamiento de datos personales;
    </p>

    <br />
    <h2>DATO PÚBLICO</h2>
    <p>
      Es el dato que no sea semiprivado, privado o sensible. Son considerados datos PÚBLICOS, entre otros: DATOS RELATIVOS AL ESTADO CIVIL DE LAS PERSONAS, A SU
      PROFESIÓN U OFICIO Los contenidos en gacetas y sentencias judiciales debidamente ejecutoriadas que no estén sometidas a reserva;
    </p>

    <br />
    <h2>DATO SEMIPRIVADO</h2>
    <p>
      Aquél dato que no tiene naturaleza íntima, reservada, ni pública y cuyo conocimiento o divulgación puede interesar no sólo a su titular sino a cierto
      sector o grupo de personas, como el dato financiero y crediticio de actividad comercial o de servicios.
    </p>

    <br />
    <h2>DATO PRIVADO</h2>
    <p>Es el dato que por su naturaleza íntima o reservada sólo es relevante para el titular.</p>

    <br />
    <h2>DATO PERSONAL</h2>
    <p>
      Es la información vinculada a una(s) persona(s) determinada(s). No aplica para datos impersonales. El dato se presume que se trata de uso personal, los
      cuales pueden ser públicos, semiprivados o privados.
    </p>

    <br />
    <h2>DATOS SENSIBLES</h2>
    <p>
      Aquellos que afectan la intimidad del Titular o cuyo uso indebido puede generar su discriminación, tales como los que revelen el origen racial o étnico,
      la orientación política, las convicciones religiosas o filosóficas, la pertenencia a sindicatos, organizaciones sociales, de derechos humanos o que
      promueva intereses de cualquier partido, así como los relativos a la salud, a la vida sexual, y los datos biométricos;
    </p>

    <br />
    <h2>RESPONSABLE DEL TRATAMIENTO</h2>
    <p>Persona natural o jurídica, pública o privada, que por sí misma o en asocio con otros, decida sobre la base de datos y/o el tratamiento de los datos;</p>
  </section>

  <section class="section-green">
    <br />
    <h2>ENCARGADO DEL TRATAMIENTO</h2>
    <p>
      Persona natural o jurídica, pública o privada, que por sí misma o en asocio con otros, realice el tratamiento de datos personales por cuenta del
      Responsable del Tratamiento;
    </p>
    <br />
    <h2>TITULAR</h2>
    <p>Persona natural cuyos datos personales sean objeto de tratamiento.</p>

    <br />
    <h2>TRATAMIENTO</h2>
    <p>Cualquier operación o conjunto de operaciones sobre datos personales, tales como la recolección, almacenamiento, uso, circulación o supresión.</p>

    <br />

    <h2>CAPITULO I</h2>

    <br />

    <h2>ACTORES / BASES DE DATOS OBJETO DE PROTECCIÓN</h2>
    <p>
      Para efectos de establecer el ámbito de aplicación sobre el cual recae la presente Política de Protección de datos, se determinan los siguientes actores /
      bases de datos, por estar involucrados en el desarrollo del objeto social de la Fundación:
    </p>
    <h2>DONANTES</h2>
    <h2>PROYECTOS</h2>
    <h2>PROVEEDORES</h2>

    <br />
    <p>
      Aquellas personas naturales o jurídicas, en calidad de donantes, prospectos o interesados de origen Nacional o Internacional, que proveen de recursos de
      libre destinación o, específica para la implementación de proyectos. Congruente con las Políticas de rendición de cuentas, son receptores de informes
      sobre los avances de los proyectos y programas que se adelanta con sus recursos.
    </p>

    <br />
    <p>Las bases de datos correspondientes a donantes se trataran por las áreas que recauden la información.</p>
  </section>
  <section class="section-pink">
    <p>
      Fundación Plan para la implementación de los proyectos que se derivan del objeto social, consolida diversas bases de datos, entre estas las siguientes:
    </p>
    <p>
      • Datos personales y/o sensibles transmitidos para la implementación de proyectos en virtud de alianzas estratégicas, para tratamiento de información de
      acuerdo a la presente política e interacción con la comunidad.
    </p>
    <p>• Recopilación de datos personales y/o sensibles de forma directa con la comunidad para el tratamiento de acuerdo a la presente política</p>
    <p>
      Todas aquellas personas naturales o jurídicas que llevan a cabo la prestación de un bien o servicio para las actividades propias de la Fundación. Se
      encuentran en este grupo los proveedores clasificados en listas de proveedores elegibles, quienes participan en campañas o quienes celebran contratos con
      la Fundación.
    </p>

    <br />

    <h2>CAPITULO II</h2>

    <br />

    <h2>TRATAMIENTO Y FINALIDAD DE LA PROTECCIÓN DE DATOS</h2>
    <p>
      En la aplicación de la Política de protección de datos, se presentan las siguientes etapas que conforman el procedimiento mediante el cual debe tratarse
      la información contenida en las bases de datos que administra la Fundación:
    </p>

    <br />
    <h2>2.1 RECOLECCIÓN DE LA INFORMACIÓN</h2>

    <p>
      La recolección de la información de propiedad de los actores que componen el desarrollo del objeto social de la Fundación, se realiza en forma directa o a
      través de terceros relacionados contractualmente con Fundación Plan, medios digitales, actividades de mercadeo, comunicaciones, servicio al cliente, así
      como cualquier otra actividad relacionada con el desarrollo de nuestros proyectos comunitarios alineados con la misión y visión de la Fundación, Enfocadas
      a lograr mejoras duraderas en la calidad de vida de los niños y las niñas marginados en Colombia
    </p>

    <br />
    <h2>2.2 ALMACENAMIENTO, ADMINISTRACIÓN Y USO DE LA INFORMACIÓN.</h2>

    <p>
      El almacenamiento, administración y uso de los datos personales sobre los cuales recae la protección legal, se lleva a cabo a través de medios seguros y
      confidenciales debido a que contamos con el talento humano y la infraestructura tecnológica necesaria para asegurar que su información sea administrada
      bajo parámetros de seguridad y confidencialidad que la Fundación debe garantizar como la responsable en el tratamiento de la información sometida a los
      efectos de esta Política.
    </p>
    <br />

    <p>
      Para el tratamiento de bases de datos derivadas de los proyectos que ejecute la fundación, este podrá darse de forma directa o indirecta mediante alianzas
      estratégicas que cumplan y aseguren la información de manera confidencial y con pleno cumplimiento de los parámetros de ley para la protección de los
      datos personales.
    </p>
  </section>

  <section class="section-green">
    <h2>2.3 Utilización de las cookies</h2>
    <p>
      El sitio web de la Fundación Soliun emplea herramientas de seguimiento de usuarios en la web para ayudarnos a comprender cómo navegan nuestros usuarios en
      nuestra página de internet. Esta herramienta analítica emplea “cookies” las cuales son archivos de texto que se sitúan en su ordenador con el fin de
      recabar información estándar relativa a la conexión de Internet y el comportamiento de los visitantes del sitio web de forma anónima.
    </p>

    <br />

    <p>
      La información que recaba la cookie es relativa a la utilización que el usuario hace del sitio web (incluyendo la dirección IP), esta se transmite a un
      motor de búsqueda. Posteriormente, dicha información se emplea con el fin de evaluar el uso que hacen los usuarios del sitio web y con la idea de compilar
      informes estadísticos para la Fundación Soliun sobre la actividad del sitio web en cuestión.
    </p>

    <br />

    <p>
      En el marco vigente de la presente Política, la Fundación Soliun no permitirá que ningún tercero emplee la herramienta de análisis estadístico con el fin
      de rastrear o recabar cualquier información identificable de carácter personal de los visitantes de nuestro sitio web, adoptando las medidas preventivas y
      restrictivas pertinentes. No asociaremos ninguna información recopilada a través de nuestro sitio web con ninguna información identificable de carácter
      personal proveniente de cualquier fuente de nuestro uso de la herramienta del motor de búsqueda de análisis estadístico La. Fundación Soliun, no vinculará
      o intentará establecer nexo alguno entre la dirección IP y la identidad del usuario de un ordenador.
    </p>
    <br />

    <p>
      Se pueden aceptar o rechazar las cookies modificando la configuración de su explorador. Consulte el proceso de eliminación de cookies en la sección de
      soporte de su navegador.
    </p>
  </section>

  <section class="section-pink">
    <h2>2.4. Uso específico de la información obtenida por la Fundación.</h2>
    <p>
      La información personal que es recibida por parte de la Fundación será usada para realizar satisfactoriamente el proceso de donación u otra transacción y
      contar con la posibilidad de contactar al donante (por correo electrónico o teléfono) para brindarle información sobre la misión de la Fundación Soliun,
      las formas en las que puede ayudar, efectuar la rendición de cuentas correspondiente o adelantar las campañas de mercadeo y comunicación propias de las
      actividades de la Fundación Soliun. Así mismo, para enviar información y rendición de cuentas acerca de los proyectos y programas que realiza la fundación
      o solicitar cotización de productos y servicios que se requieran para la ejecución del objeto social.
    </p>

    <br />

    <p>
      De igual forma, en los procesos contractuales que adelante la Fundación, se aplican las Políticas de confidencialidad, las cuales se incorporan en las
      ofertas, convenios, contratos y demás acuerdos que se celebren con terceros, que se tramiten en las diferentes áreas de la organización, con el fin de
      adquirir bienes y/o servicios. También en este escenario se cuenta con la protección y regulación de propiedad intelectual y de los documento, en la
      medida en que cualquier información, documentos, análisis, conocimiento y demás insumo derivado de la ejecución de un contrato, sea de propiedad de la
      Fundación, cumpliendo con sus deberes como responsable de la información.
    </p>

    <br />
    <h2>2.5. Medidas adicionales para el tratamiento de datos sensibles.</h2>

    <p>
      Fundación Plan, en virtud de su rol como responsable de la información, y en garantía del Habeas Data y del Derecho a la Intimidad aplicará y respetará
      las limitaciones y restricciones legales al tratamiento de datos sensibles, por lo cual se asegurará de forma previa al tratamiento de la información, que
      el titular de la misma, haya suministrado su autorización explícita a dicho tratamiento, en las condiciones y forma señaladas, salvo en los casos
      contemplados en el artículo 6 de la ley 1581 de 2012, desarrollados en el citado capítulo.
    </p>
    <br />

    <p>
      Las anteriores medidas, son aplicadas desde la recolección, el almacenamiento, uso, tratamiento y circulación de datos, de tal forma que se garantice en
      todo momento la protección de los datos personales objeto de la presente Política.
    </p>
  </section>

  <section class="section-green">
    <h2>2.6. ACTUALIZACIÓN, RECTIFICACIÓN Y SUPRESIÓN.</h2>
    <p>
      La Fundación Soliun, en observancia de lo contemplado en el artículo 22 del Decreto reglamentario 1377 de 2013, adoptará en el tratamiento de los datos
      personales, las medidas necesarias y razonables para garantizar que los datos personales contenidos en las bases de datos cumplan con criterios de
      veracidad, precisión y suficiencia. De esta manera, cuando el titular de la información requiera la actualización, rectificación o supresión, tendrá la
      oportunidad de efectuar la solicitud a la Fundación Soliun, quien cuenta como responsable de la información, con el procedimiento a que haya lugar para el
      efecto.
    </p>

    <br />

    <p>
      El titular de la información que desee la eliminación o supresión de la información que repose en las bases de datos de la Fundación Soliun, podrá
      solicitarla de forma parcial o total, en cualquier momento y en los siguientes eventos, salvo en los casos en que la Fundación requiera conservarla en
      razón a un vínculo contractual, legal o judicial debidamente demostrado:
    </p>

    <br />

    <p>
      Cuando el titular considere que sus datos personales no están siendo debidamente protegidos y por lo tanto se esté incumpliendo con el mandato legal y la
      Política derivada del mismo; Cuando no subsista la finalidad o destinación que dio lugar al tratamiento de datos personales por parte de la Fundación; En
      el evento en que los datos personales hayan culminado el término o duración por la que fueron requeridos; Cualquier otra causal que el titular argumente y
      por la cual deba atender de forma diligente a su solicitud.
    </p>
    <br />
    <h2>CAPITULO III</h2>
    <h2>DERECHOS Y DEBERES</h2>
    <h2>3.1. Derechos del titular de los datos personales</h2>

    <p>De acuerdo a lo establecido en el artículo 8 de la ley 1581 de 2012, todo titular tiene los siguientes derechos:</p>
    <p>- Conocer, actualizar y rectificar sus datos personales frente a la Fundación Soliun.</p>
    <p>- Los datos sean parciales, inexactos, incompletos, fraccionados o que induzcan a error.</p>
    <p>- El tratamiento esté prohibido o no haya sido autorizado.</p>
    <p>- Solicitar prueba de la autorización otorgada a la fundación Soliun, en su calidad de responsable del tratamiento.</p>
    <p>- Ser informado por Fundación Plan, del uso que le ha dado a sus datos personales, siempre que realice una solicitud.</p>

    <br />
    <p>
      Presentar ante la Superintendencia de Industria y Comercio quejas por infracciones a lo dispuesto en la ley 1581 de 2012, de forma posterior a agotar el
      trámite de consulta o reclamo ante Fundación Plan. Revocar la autorización y/o solicitar la supresión del dato cuando en el Tratamiento no se respeten los
      principios, derechos y garantías constitucionales y legales. Acceder en forma gratuita a sus datos personales que hayan sido objeto de Tratamiento.
    </p>
  </section>

  <section class="section-pink">
    <h2>CAPITULO IV</h2>
    <h2>AUTORIZACIONES</h2>
    <h2>4.1. Autorización.</h2>
    <p>
      La fundación Soliun, en su calidad de responsable del tratamiento de datos personales, requiere por mandato legal autorización libre y expresa, expedida
      por el titular, para la recolección, uso, almacenamiento circulación y supresión de los datos personales, la cual constara por medio físico o electrónico,
      garantizando la debida custodia y consulta de la misma.
    </p>

    <br />
    <h2>4.2. Mecanismos para otorgar la autorización.</h2>
    <p>
      La autorización puede constar en documento físico, electrónico, o en cualquier otro formato que de forma posterior permita su consulta, para ello, la
      Fundación Soliun ha dispuesto de los siguientes mecanismos.
    </p>

    <br />
    <h2>4.2.1. Recopilación de datos.</h2>

    <p>
      PARA DONANTES Y PROSPECTOS: La fundación Soliun dispone dentro de su sitio web, el acceso a la presente política con el fin de informar al titular de sus
      derechos y la finalidad del tratamiento de sus datos, previo a realizar la autorización cuyo texto se encuentra antes de permitir el envío de la
      información a nuestro portal.
    </p>

    <p>
      PARA LA EJECUCIÓN DE PROYECTOS: Cuando en la ejecución de proyectos se requiera el recaudo de información personal y/o de carácter sensible, la Fundación
      Soliun pondrá en conocimiento del titular sus derechos y solicitará la autorización respectiva, aclarando el tratamiento que se dará a su información
      sensible así como la finalidad y la posibilidad de negarse a realizar la autorización.
    </p>
    <p>
      PARA PROVEEDORES Y CONTRATISTAS: La Fundación Soliun en el momento de requerir el servicio, entregará al titular el formato de autorización el cual
      contiene la ruta electrónica de acceso a la presente política e informa de la finalidad del recaudo y tratamiento de los datos.
    </p>

    <br />
    <h2>4.2.2. Datos recopilados con anterioridad a la vigencia de la presente política.</h2>
    <p>
      AVISO DE PRIVACIDAD: el aviso de privacidad es el documento físico, electrónico, o cualquier otro formato, que la Fundación Soliun ha implementado en
      cumplimiento de la ley de protección de datos, para informar al titular de la existencia de los mismos en las bases de datos de PLAN, así como, del
      tratamiento y finalidad que se ha dado a su información y los derechos que tiene a autorizar el tratamiento, o solicitar la supresión de sus datos de
      nuestras bases.
    </p>
  </section>
  <section class="section-green">
    <p>El aviso contiene:</p>
    <p>- Los datos de identificación y contacto de la Fundación Soliun en su calidad de responsable.</p>

    <p>
      - El tratamiento al cual serán sometidos los datos y la finalidad del mismo. Los mecanismos para la consulta de la política de protección de datos y los
      medios por los cuales será informado de cualquier cambio de la misma.
    </p>
    <p>- Los derechos que tiene como titular.</p>
    <p>
      - La transmisión del aviso de privacidad se conservara mientras perdure en las obligaciones frente al tratamiento de los datos autorizados, en medios
      idóneos para su conservación y consulta.
    </p>
    <br />

    <h2>4.3. Excepciones a la Autorización.</h2>
    <p>
      En todos los casos se solicitara la respectiva autorización para el tratamiento de datos, excepto en las situaciones que plantea expresamente la ley 1581
      de 2012 y que se describen a continuación:
    </p>
    <p>
      Cuando el Tratamiento de los datos sensibles es efectuado en el curso de las actividades legítimas y con las debidas garantías por parte de una fundación,
      como es el caso de la Fundación Soliun, siempre que se refieran exclusivamente a sus miembros o a las personas que mantengan contactos regulares por razón
      de su finalidad. En estos eventos, los datos no se podrán suministrar a terceros sin la autorización del Titular.
    </p>
    <p>
      Cuando el Tratamiento de los datos sensibles tenga una finalidad histórica, estadística o científica, caso en el cual se adoptaran las medidas conducentes
      a la supresión de identidad de los Titulares. Cuando los datos sean de carácter público.
    </p>

    <br />

    <h2>4.3. Excepciones a la Autorización.</h2>
    <p>
      En todos los casos se solicitara la respectiva autorización para el tratamiento de datos, excepto en las situaciones que plantea expresamente la ley 1581
      de 2012 y que se describen a continuación:
    </p>
    <p>
      Cuando el Tratamiento de los datos sensibles es efectuado en el curso de las actividades legítimas y con las debidas garantías por parte de una fundación,
      como es el caso de la Fundación Soliun, siempre que se refieran exclusivamente a sus miembros o a las personas que mantengan contactos regulares por razón
      de su finalidad. En estos eventos, los datos no se podrán suministrar a terceros sin la autorización del Titular.
    </p>
    <p>
      Cuando el Tratamiento de los datos sensibles tenga una finalidad histórica, estadística o científica, caso en el cual se adoptaran las medidas conducentes
      a la supresión de identidad de los Titulares. Cuando los datos sean de carácter público.
    </p>
    <br />

    <h2>4.4. Revocatoria de la autorización y/o supresión del dato.</h2>
    <p>
      Los titulares podrán en cualquier momento solicitar a la Fundación Soliun la supresión de sus datos o revocar la autorización otorgada para el tratamiento
      de los mismos mediante la presentación de un reclamo de acuerdo al procedimiento establecido en el capítulo sexto de la presente política.
    </p>
    <br />
    <h2>CAPITULO V</h2>
    <h2>PROCEDIMIENTO PARA EL ACCESO, CONSULTA Y RECLAMACIÓN.</h2>
    <p>
      Los titulares podrán en todo momento tener acceso a sus datos personales, y conocer el tratamiento de los mismos, así mismo, podrá solicitar la supresión
      de sus datos de las bases de datos que tiene la Fundación Soliun, revocar su autorización o rectificar los datos cuando sea esto necesario, para lo cual
      la Fundación Soliun pone a su disposición, mecanismos de contacto electrónico a los cuales podrá comunicarse con el fin de realizar su solicitud.
    </p>
  </section>
  <section class="section-pink">
    <p>
      Para efectos del presente capitulo, se entenderá por solicitud, todo requerimiento del titular sobre su información, contenido y tratamiento, así como las
      situaciones en las que se busque revocar la autorización, corregir o actualizar los datos o realizar el reclamo por inconsistencias o violaciones al
      tratamiento de los datos personales.
    </p>
    <br />
    <p>
      Teniendo en cuenta lo dispuesto en el artículo 13° de la Ley 1581 de 2012, la Fundación Soliun podrá suministrar los datos sin incurrir en una violación
      al debido uso de la información, cuando la solicitud sea realizada por alguna de las siguientes personas:
    </p>

    <p>- Titulares, causahabientes o sus representantes legales.</p>
    <p>- Entidades públicas o administrativas en ejercicio de sus funciones legales o por orden judicial.</p>
    <p>A los terceros autorizados por el Titular o por la ley.</p>
    <br />

    <p>
      Independientemente del mecanismo que utilice el titular, la Fundación Soliun atenderá las solicitudes y dará respuesta dentro de los 10 días siguientes a
      la recepción del requerimiento. En caso de que la consulta requiera un tiempo mayor, la Fundación Soliun informara al titular los motivos de la demora y
      la ampliación del plazo para dar respuesta, que en ningún caso superará los cinco días adicionales. En caso de que la solicitud del titular no cuente con
      la información completa que permita realizar el trámite respectivo, la Fundación Soliun requerirá la información adicional dentro de los 5 días siguientes
      a la fecha de recepción de la solicitud. Durante el tiempo que tarde el titular en subsanar su requerimiento, el procedimiento se encontrara suspendido.
      Cuando el titular atienda la solicitud, la Fundación Soliun reanudará el trámite dando respuesta oportuna dentro de los cinco días siguientes.
    </p>
    <br />
    <p>Si el titular no subsana su solicitud dentro de los 2 meses siguientes, la Fundación Soliun podrá archivar su requerimiento.</p>
    <br />
    <p>
      Cuando la solicitud sea presentada por una persona no autorizada para ello de acuerdo a lo planteado en el presente capitulo, la Fundación Soliun hará uso
      del procedimiento establecido en el párrafo anterior.
    </p>

    <br />

    <h2>CAPITULO VI</h2>
    <h2>ENCARGADO</h2>
    <p>
      La Fundación Soliun designa a las áreas de Comunicaciones e Implementación Programática para cumplir con la función de protección de datos personales para
      que lleve a cabo los trámites de las solicitudes de los titulares y se escale en cada caso las solicitudes siempre en cumplimiento del procedimiento
      establecido y la debida protección a los datos de los titulares.
    </p>
    <br />
    <p>
      La dependencia encargada de los asuntos vinculados a la protección de datos es el Departamento de Cundinamarca ubicada en la Carrera 70 C # 48-55 de
      Bogotá D.C.
    </p>
  </section>
</div>
