<div class="page-campaign">

  <ng-progress #progressBar [color]="'#e0404d'" [thick]="false"></ng-progress>

  <section class="section__not_found" *ngIf="campaign && !isActive">
    <h1>Campaña no disponible</h1>
    <img src="../../../assets/placeholders/campaign_not_found.jpg">
  </section>

  <section class="page-section campaing-wrapper" *ngIf="campaign && isActive">
    <div class="wrapper">
      <div class="col">
        <ui-photo-gallery
          (nChange)="updatePictures($event)"
          (nDelete)="deletePicture($event)"
          [editable]="isOrganizer"
          [photos]="campaign.photos"
          [isBulletsVertical]="true"
        ></ui-photo-gallery>

        <ui-campaign-tabs>
          <ui-campaign-tab [tabTitle]="'DESCRIPCIÓN DEL PROYECTO'">
            <ui-campaign-description
              #campaignDescription
              [editable]="isOrganizer"
              [json]="campaignJSON"

              (nChange)="updateDescription($event)"
              [description]="campaign.description"
              [content]="campaign.content"
            >
            </ui-campaign-description>
          </ui-campaign-tab>
          <ui-campaign-tab [tabTitle]="'COMENTARIOS'" *ngIf="!isOrganizer">
            <ui-campaign-comments [comments]="campaign.comments"></ui-campaign-comments>
          </ui-campaign-tab>
          <ui-campaign-tab [tabTitle]="'ÚLTIMOS DONANTES'" *ngIf="!isOrganizer">
            <ui-campaign-last-donors [donors]="campaign.donors"></ui-campaign-last-donors>
          </ui-campaign-tab>
        </ui-campaign-tabs>
      </div>
      <div class="col">
        <ui-campaign-info
          (nChange)="updateInfo($event)"
          [editable]="isAdmin"
          [title]="campaign.title"
          [photo]="campaign.organizer.photo_url"
          [company]="campaign.organizer.company"
          [name]="campaign.organizer.name"
          [position]="campaign.organizer.position"
          [category]="campaign.category"
          [donated]="campaign.donated"
          [goal]="campaign.goal"
          [like]="false"
          [start]="campaign.date_start"
          [end]="campaign.date_end"
          [versions]="campaign.update_remains || 3"
          [campaign] = "campaign"
        ></ui-campaign-info>

        <div class="campaign-buttons" *ngIf="!isOrganizer">
          <div class="wrapper x-extremes y-center">
            <ui-solid-btn
              [color]="'red'"
              [minWidth]="'250px'"
              [margins]="'0em'"
              [paddings]="'1em'"
              [text]="'campaigns.donate'"
              (nClick)="doDonate()"
            ></ui-solid-btn>
            <ui-outline-btn [color]="'red'" [margins]="'0em'" [paddings]="'1em'" [minWidth]="'250px'" [text]="'campaigns.share'" (nClick)="openModal()">
            </ui-outline-btn>
          </div>
        </div>

        <div class="campaign-buttons" *ngIf="isOrganizer">
          <div class="wrapper x-extremes y-center">
            <ui-outline-btn
              [color]="'red'"
              [minWidth]="'250px'"
              [margins]="'0em'"
              [paddings]="'1em'"
              [text]="'GUARDAR EDICIÓN'"
              (nClick)="doSave()"
            ></ui-outline-btn>

            <ui-solid-btn
            [color]="'green'"
            [margins]="'0em'"
            [paddings]="'1em'"
            [minWidth]="'250px'"
            [text]="'VISTA PREVIA'"
            (nClick)="doPreview()">
            </ui-solid-btn>
          </div>
        </div>

        <ui-campaign-donors *ngIf="!isOrganizer && campaign.stats"
          [id]="campaign.id"
          [total]="campaign.stats.total"
          [last24hrs]="campaign.stats.last24hrs">
        </ui-campaign-donors>



        <ui-campaign-map (nChange)="updateLatLng($event)" [place_city]="campaign.place_city" [editable]="isOrganizer" [place]="campaign.place"> </ui-campaign-map>

        <div class="campaing-about-us x-extremes y-center">
          <h2 class="campaing-about-us--how">
            {{ 'campaigns.how_we_work' | translate }}
          </h2>
          <h2 class="campaing-about-us--report x-end y-center">
            <img src="../../../assets/icons/flag.png" />
            <a>
              {{ 'campaigns.report' | translate }}
            </a>
          </h2>
        </div>
      </div>
    </div>
  </section>


  <section class="page-section similar" style="background-color: #fff4f5">
    <div class="section--wrapper">
      <h1 class="section-title">CAMPAÑAS {{ categoryA }}</h1>
      <ui-home-category-campaigns [campaigns]="campaigns$ | async | filterByCategory: categoryA | filterByExcludingId: idA"> </ui-home-category-campaigns>
    </div>
  </section>

  <section class="page-section faq">
    <div class="faq--wrapper">
      <ul>
        <li>
          <strong>La unidad como formula de crecimiento.</strong> Creemos en la colaboración con otras organizaciones e instituciones para potenciar sinergias y
          mejorar el rendimiento del trabajo de la fundación.
        </li>
        <li>
          <strong>Nuestro compromiso social es</strong> trabajar con población que se encuentra en situaciones de mayor vulnerabilidad, con los procesos de
          integración intercultural y deportiva.
        </li>
        <li>
          <strong>La confianza es importante.</strong> Generamos relaciones a nivel interno y externo bajo dinámicas fluidas y eficaces promoviendo la
          comunicación asertiva.
        </li>
      </ul>
      <div class="line"></div>
    </div>
  </section>

  <ui-share-modal *ngIf="isModal" (nClose)="closeModal()" [campaign]="campaign"></ui-share-modal>

</div>


<p-dialog
    *ngIf="displayModal"
    [modal]="isModal"
    [styleClass]="'modal--status'"
    [(visible)]="displayModal"
    [closeOnEscape]="true"
    [baseZIndex]="10000"
    [draggable]="false"
    [closable]="true"
    [resizable]="false">

      <img *ngIf="status === 'ACCEPTED'" class="accepted-icon" src="../../../assets/modals/modal_icon1.png">
      <img *ngIf="status === 'PENDING'" class="accepted-icon" src="../../../assets/modals/modal_icon5.png">
      <img *ngIf="status === 'REJECTED'" class="accepted-icon" src="../../../assets/modals/modal_icon3.png">

      <h1 *ngIf="status === 'ACCEPTED'">¡Donación exitosa!</h1>
      <h1 *ngIf="status === 'PENDING'">¡Donación pendiente!</h1>
      <h1 *ngIf="status === 'REJECTED'">¡Donación fallida!</h1>

      <p  *ngIf="status === 'ACCEPTED'">
        Trabajaremos para que tu dinero llegue a su destino,
        sabrás el paso a paso del proceso de esta campaña en tu perfil. Verás la donación cuando la pasarela de pago lo verifique (aproximadamente 15 minutos).
      </p>

      <p *ngIf="status === 'PENDING'">
        Se estará notificando a tu correo electrónico el proceso.
        Tendrás una respuesta exitosa o fallida. Si es fallida, podrás repetir el proceso nuevamente.
      </p>

      <p *ngIf="status === 'REJECTED'">
        Lo sentimos, algo ha salido mal. Puede ser por un problema de plataformas o debido tu conexión de internet. Por favor revisa, refresca la página y vuelve a intentarlo.
      </p>

      <img *ngIf="status === 'ACCEPTED'" class="rainbow-icon" src="../../../assets/modals/modal_icon2.png">
      <img *ngIf="status !== 'ACCEPTED'" class="rainbow-icon" src="../../../assets/modals/modal_icon4.png">

</p-dialog>
