import { HomePageComponent } from './home-page/home-page.component';

import { AboutPageComponent } from './about-page/about-page.component';
import { AboutUsComponent } from './about-page/components/about-us/about-us.component';
import { AboutWorkComponent } from './about-page/components/about-work/about-work.component';
import { PoliticsPageComponent } from './politics-page/politics-page.component';
import { TermsPageComponent } from './terms-page/terms-page.component';
import { RequirementsPageComponent } from './requirements-page/requirements-page.component';
import { AlternativePageComponent } from './auth-pages/alternative-page/alternative-page.component';
import { CampaignPageComponent } from './campaign-page/campaign-page.component';
import { CampaignsPageComponent } from './campaigns-page/campaigns-page.component';
import { ProfilePageComponent } from './profile-page/profile-page.component';
import { CheckoutPageComponent } from './checkout-page/checkout-page.component';

import { CreatePageComponent } from './create-page/create-page.component';
import { LegalPageComponent } from './legal-page/legal-page.component';

export const PagesRoutes = [
  //  Home Page Component
  {
    path: '',
    component: HomePageComponent,
    // component: LegalPageComponent,
    canActivate: [],
    children: [],
  },

  //  About Page Component
  {
    path: 'about/us',
    component: AboutUsComponent,
    canActivate: [],
  },
  {
    path: 'about/work',
    component: AboutWorkComponent,
    canActivate: [],
  },

  //  Politics Page Component
  {
    path: 'politics',
    component: PoliticsPageComponent,
    canActivate: [],
  },

  //  Terms Page Component
  {
    path: 'terms',
    component: TermsPageComponent,
    canActivate: [],
  },

  //  Requirements Page Component
  {
    path: 'requirements',
    component: RequirementsPageComponent,
    canActivate: [],
  },

  //  Campaings Page Component
  {
    path: 'campaigns',
    component: CampaignsPageComponent,
    canActivate: [],
  },
  {
    path: 'campaigns/:type',
    component: CampaignsPageComponent,
    canActivate: [],
  },

  //  Campaing Page Component
  {
    path: 'campaing/:id',
    component: CampaignPageComponent,
  },

  //  Campaing Page Component
  {
    path: 'campaing/:id/modal/payment/:status',
    component: CampaignPageComponent,
  },

  //  Checkout Page Component
  {
    path: 'checkout/:id',
    component: CheckoutPageComponent,
  },

  //  Profile Page Component
  {
    path: 'profile',
    component: ProfilePageComponent,
    canActivate: [],
  },

  {
    path: 'profile/user/id/:id',
    component: ProfilePageComponent,
    canActivate: [],
  },

  {
    path: 'create/campaign',
    component: CreatePageComponent,
    canActivate: [],
  },
  {
    path: 'create/campaign/type/:type',
    component: CreatePageComponent,
    canActivate: [],
  },
  {
    path: 'create/campaign/thanks',
    component: CreatePageComponent,
    canActivate: [],
  },
  {
    path: 'legal',
    component: LegalPageComponent,
    canActivate: [],
  },
];
