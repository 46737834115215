<div class="page-home">
  <section class="cover">
    <div class="section--wrapper">
      <ui-home-cover [campaigns]="campaigns$ | async | filterByIsCover"></ui-home-cover>
    </div>
  </section>

  <section class="outstandings-blogs" *ngIf="false">
    <div class="section--wrapper">
      <!-- max 6 -->
      <ui-home-outstandings-blogs [campaigns]="campaigns$ | async | filterByIsOutstanding | filterByIsFinished"></ui-home-outstandings-blogs>
    </div>
  </section>

  <div *ngIf="campaigns$ | async | filterByIsOutstanding; let campaigns">
    <section class="outstandings-campaigns" *ngIf="campaigns && campaigns.length > 0">
      <div class="section--wrapper">
        <h1 class="section-title">
          <!--  3-3-3-1 (3 paga) (3 likes) (3 mas donatas) (1 al azar de las que menos tengan)
              mas donación por cantidad
              mas likes
              finaliza hoy
            -->
          CAMPAÑAS DESTACADAS
        </h1>
        <ui-home-outstandings-campaigns [campaigns]="campaigns"></ui-home-outstandings-campaigns>
      </div>
    </section>
  </div>

  <div *ngIf="campaigns$ | async | filterByCategory: categoryA | filterByIsActive; let campaigns">
    <section class="category-campaigns A" *ngIf="campaings && campaings.length > 0">
      <div class="section--wrapper">
        <h1 class="section-title">
          <!-- QUE NO SE REPITA CATEGORIA -->
          CAMPAÑAS {{ categoryA }}
        </h1>
        <ui-home-category-campaigns [campaigns]="campaings"></ui-home-category-campaigns>
      </div>
    </section>
  </div>

  <div *ngIf="campaigns$ | async | filterByCategory: categoryB | filterByIsActive; let campaigns">
    <section class="category-campaigns B" *ngIf="campaings && campaings.length > 0">
      <div class="section--wrapper">
        <h1 class="section-title">CAMPAÑAS {{ categoryB }}</h1>
        <ui-home-category-campaigns [campaigns]="campaings"></ui-home-category-campaigns>
      </div>
    </section>
  </div>

  <section class="about">
    <div class="section--wrapper" style="max-width: 100%">
      <ui-home-about></ui-home-about>
    </div>
  </section>

  <section class="purposes">
    <div class="section--wrapper">
      <ui-home-purposes></ui-home-purposes>
    </div>
  </section>

  <section class="call-to-actions">
    <div class="section--wrapper">
      <ui-home-call-to-actions></ui-home-call-to-actions>
    </div>
  </section>
</div>
