<!-- ----------------------------------- -->
<!-- --- home-page cover --------------- -->
<!-- ----------------------------------- -->

<div class="home-page cover">
  <!-- ------------------------------------ -->
  <!-- --- Swiper ------------------------- -->
  <!-- ------------------------------------ -->
  <swiper
    class="cover--swiper"
    [config]="config"
    [disabled]="disabled"
    (indexChange)="onIndexChange($event)"
    (swiperTransitionStart)="onSwiperEvent('transitionStart')"
    (swiperTransitionEnd)="onSwiperEvent('transitionEnd')"
  >
    <!-- Slides -->
    <div class="cover--slide" *ngFor="let campaign of campaigns | slice: 0:10">
      <div class="cover--slide--wrapper" *ngIf="campaign">
        <div class="slide--bg">
          <img *ngIf="campaign.photos[0]" [defaultImage]="campaign.photos[0]" [lazyLoad]="campaign.photos[0]" [useSrcset]="true" />
          <img class="bg-gradient-layer" src="../../../../../assets/images/bg-gradient.png" />
        </div>

        <div class="slide--content">
          <h1>{{ campaign.title | truncateText: 20 }}</h1>
          <ui-text-read-only-editor-js [description]="campaign.description"></ui-text-read-only-editor-js>
          <p (click)="goToCampaign(campaign)">
            <a style="color:white"> VER MÁS </a>
          </p>
        </div>
      </div>
    </div>
  </swiper>
  <!-- ----------------------------------- -->

  <!-- ----------------------------------- -->
  <!-- --- Botones de Categorias --------- -->
  <!-- ----------------------------------- -->
  <div class="cover--categories" (clickOutside)="setContainer('')" *ngIf="false">
    <h1>{{ 'home.swiper.categoriesButtonsTitle' | translate }}</h1>
    <div class="buttons">
      <button (click)="setContainer('places')">
        <span>
          {{ 'home.swiper.categoriesButtonPlaces' | translate }}
        </span>
        <img src="../../../../../assets/icons/dropdown-icon.png" />
      </button>
      <button (click)="setContainer('categories')">
        <span>
          {{ 'home.swiper.categoriesButtonCategories' | translate }}
        </span>
        <img src="../../../../../assets/icons/dropdown-icon.png" />
      </button>
    </div>
    <div
      class="containers"
      *ngIf="container"
      [ngStyle]="{
        'border-radius': container === 'categories' ? '10em' : '2em',
        width: container === 'categories' ? '440px' : 'fit-content'
      }"
    >
      <div class="container places" *ngIf="container === 'places'" [@enterAnimation]>
        <br />
        <div class="row x3">
          <div class="col">
            <ui-outline-btn [minWidth]="'200px'" [color]="'none'" [text]="'Bogotá'"></ui-outline-btn>
          </div>
          <div class="col">
            <ui-outline-btn [minWidth]="'200px'" [color]="'none'" [text]="'Medellín'"></ui-outline-btn>
          </div>
          <div class="col">
            <ui-outline-btn [minWidth]="'200px'" [color]="'none'" [text]="'Barranquilla'"></ui-outline-btn>
          </div>
        </div>
        <br />
        <div class="row x1">
          <div class="col col--y-center-x-center" style="width: 100%">
            <ui-search-input (nChoose)="setCity($event)" [options]="[]" [placeholder]="'Buscar en otras ciudades'"></ui-search-input>
          </div>
        </div>
      </div>
      <div class="container categories" *ngIf="container === 'categories'" [@enterAnimation]>
        <div class="row x2">
          <div class="col">
            <ui-radio-btn-input [color]="'red'" [text]="'Fundaciónes'" (nClick)="setSearchByCampaignType('fundations')"></ui-radio-btn-input>
          </div>
          <div class="col">
            <ui-radio-btn-input [color]="'green'" [text]="'Causas'" (nClick)="setSearchByCampaignType('causes')"></ui-radio-btn-input>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- ----------------------------------- -->
</div>
