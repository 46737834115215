import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { PagesRoutes } from './pages.routes';

import { HomePageModule } from './home-page/home-page.module';
import { CreatePageModule } from './create-page/create-page.module';
import { CampaignPageModule } from './campaign-page/campaign-page.module';
import { ProfilePageModule } from './profile-page/profile-page.module';

import { ProfilePageComponent } from './profile-page/profile-page.component';
import { HomePageComponent } from './home-page/home-page.component';
import { CampaignPageComponent } from './campaign-page/campaign-page.component';
import { CampaignsPageComponent } from './campaigns-page/campaigns-page.component';
import { AboutPageComponent } from './about-page/about-page.component';
import { CheckoutPageComponent } from './checkout-page/checkout-page.component';
import { CreateCampaignMenuPageComponent } from './create-page/create-campaign-menu-page/create-campaign-menu-page.component';
import { CreateCampaignCausePageComponent } from './create-page/create-campaign-cause-page/create-campaign-cause-page.component';
import { CreateCampaignFoundationPageComponent } from './create-page/create-campaign-foundation-page/create-campaign-foundation-page.component';
import { CreateCampaignThanksPageComponent } from './create-page/create-campaign-thanks-page/create-campaign-thanks-page.component';
import { TableModule } from 'ngx-easy-table';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { SharedModule } from '../shared/shared.module';
import { LegalPageModule } from './legal-page/legal-page.module';
import { AlternativePageComponent } from './auth-pages/alternative-page/alternative-page.component';

import { AuthPagesModule } from './auth-pages/auth-pages.module';
import { CoreModule } from '../core/core.module';
import { PoliticsPageComponent } from './politics-page/politics-page.component';
import { TermsPageComponent } from './terms-page/terms-page.component';
import { RequirementsPageComponent } from './requirements-page/requirements-page.component';

import { CampaignsPageModule } from './campaigns-page/campaigns-page.module';

import { InputSwitchModule } from 'primeng/inputswitch';
import { AdminPagesModule } from './admin-pages/admin-pages.module';
import { Profile2PagesModule } from './profile2-pages/profile2-pages.module';

@NgModule({
  declarations: [
    ProfilePageComponent,
    HomePageComponent,
    CampaignPageComponent,
    AboutPageComponent,
    CheckoutPageComponent,
    AlternativePageComponent,
    PoliticsPageComponent,
    TermsPageComponent,
    RequirementsPageComponent
  ],
  exports: [ProfilePageComponent, HomePageComponent, CampaignsPageComponent, AboutPageComponent, CheckoutPageComponent],
  imports: [
    CommonModule,
    AuthPagesModule,
    CreatePageModule,
    HomePageModule,
    LegalPageModule,
    CampaignPageModule,
    CampaignsPageModule,
    TableModule,
    ProfilePageModule,
    AutocompleteLibModule,
    SharedModule,
    CoreModule,
    InputSwitchModule,
    AdminPagesModule,
    Profile2PagesModule,
    RouterModule.forRoot(PagesRoutes, { useHash: false }),
  ],
})
export class PagesModule {

}
