<form class="form form-foundation">

  <div class="form--header">
    <div class="x">x</div>
    <div class="title">Iniciar Una Causa</div>
  </div>

  <div class="form--container">
    <div class="form-row login-row" *ngIf="!user">
      <h1>Ya tienes una cuenta, <a href="">inicia sesión</a></h1>
    </div>

    <br *ngIf="user" />

    <div class="row inputs">

      <div class="input--row x1" *ngIf="!user">
        <ui-text-input-inline
          (nChange)="setField('company', $event)"
          [errorFn]="getErrorField('company')"
          [errorText]="getErrorFieldText('company')"
          [border]="getFieldBorder('company')"
          [label]="getFieldLabel('company')"
          [type]="getFieldType('company')"
          style="width: 100%"
        ></ui-text-input-inline>
      </div>


      <div class="form-input--row-x2" *ngIf="!user">
        <ui-text-input-inline
          (nChange)="setField('phone', $event)"
          [errorFn]="getErrorField('phone')"
          [errorText]="getErrorFieldText('phone')"
          [border]="getFieldBorder('phone')"
          [label]="getFieldLabel('phone')"
          style="width: calc(50% - 1em)"
          [type]="getFieldType('phone')"
        ></ui-text-input-inline>
        <ui-text-input-inline
          (nChange)="setField('mobile', $event)"
          [errorFn]="getErrorField('mobile')"
          [errorText]="getErrorFieldText('mobile')"
          [border]="getFieldBorder('mobile')"
          [label]="getFieldLabel('mobile')"
          [type]="getFieldType('mobile')"
          style="width: calc(50% - 1em)"
        ></ui-text-input-inline>
      </div>

      <div class="form-input--row-x2" *ngIf="!user">
        <ui-text-input-inline
          (nChange)="setField('nid_number', $event)"
          [errorFn]="getErrorField('nid_number')"
          [errorText]="getErrorFieldText('nid_number')"
          [border]="getFieldBorder('nid_number')"
          [label]="getFieldLabel('nid_number')"
          [type]="getFieldType('nid_number')"
          style="width: calc(50% - 1em)"
        ></ui-text-input-inline>
        <ui-text-input-inline
          (nChange)="setField('email', $event)"
          [errorFn]="getErrorField('email')"
          [errorText]="getErrorFieldText('email')"
          [border]="getFieldBorder('email')"
          [label]="getFieldLabel('email')"
          [type]="getFieldType('email')"
          style="width: calc(50% - 1em)"
        ></ui-text-input-inline>
      </div>

      <div class="input--row x1" *ngIf="!user">
        <ui-text-input-inline
          (nChange)="setField('password', $event)"
          [errorFn]="getErrorField('password')"
          [errorText]="getErrorFieldText('password')"
          [border]="getFieldBorder('password')"
          [label]="getFieldLabel('password')"
          [type]="getFieldType('password')"
          style="width: calc(50% - 1em)"
        ></ui-text-input-inline>
        <ui-text-input-inline
          (nChange)="setField('password2', $event)"
          [errorFn]="getErrorField('password2')"
          [errorText]="getErrorFieldText('password2')"
          [border]="getFieldBorder('password2')"
          [label]="getFieldLabel('password2')"
          [type]="getFieldType('password2')"
          style="width: calc(50% - 1em)"
        ></ui-text-input-inline>
      </div>

      <h3  *ngIf="!user">Tu contraseña debe tener al menos 8 caracteres 1 letra mayuscal y 1 simbolo</h3>

      <div class="input--row x1">
        <ui-text-input-inline
          (nChange)="setField('name', $event)"
          [errorFn]="getErrorField('name')"
          [errorText]="getErrorFieldText('name')"
          [border]="getFieldBorder('name')"
          [label]="getFieldLabel('name')"
          [type]="getFieldType('name')"
          style="width: 100%"
        ></ui-text-input-inline>
      </div>
      <div class="form-input--row-x2">
        <ui-dropdown-input-c
          [i18]="true"
          (nChange)="setField('category', $event)"
          [default]="getFieldLabel('category')"
          [options]="optionsCategories"
          [border]="getFieldBorder('category')"
          [label]="getFieldLabel('category')"
          style="width: calc(50% - 1em)"
        ></ui-dropdown-input-c>
          <ui-text-input-inline
          (nChange)="setField('postcode', $event)"
          [errorFn]="getErrorField('postcode')"
          [errorText]="getErrorFieldText('postcode')"
          [border]="getFieldBorder('postcode')"
          [label]="getFieldLabel('postcode')"
          style="width: calc(50% - 1em)"
        ></ui-text-input-inline>
      </div>


      <div class="input--row x1">
        <ui-place-input style="width: 100%"
          (nChange)="setField('place', $event)"
        ></ui-place-input>
      </div>


      <div class="input--row x1">
        <ui-text-area-inline
          [advance]="true"
          (nChange)="setField('description', $event)"
          [error]="getErrorField('description')"
          [errorText]="getErrorFieldText('description')"
          [border]="getFieldBorder('description')"
          [label]="getFieldLabel('description')"
          style="width: 100%"
        ></ui-text-area-inline>
      </div>

      <div class="input--row x1 urgent">
        <span>Si es necesario que prioricemos tu solicitud, activa la</span>
        <input
          type="checkbox"
          class="input--foundationUrgent"
          (change)="setFoundationIsUrgent($event.target.value)"
          [ngClass]="{ 'input-failed': foundationIsUrgentError }"
        />
        <label>Revisión Urgente</label>
      </div>

      <br />

      <div class="input--row x1">
        <div class="wrapper" style="padding: 0.5em 1em; border-bottom: 1px solid gray">
          <span style="padding-right: 1em">Indica tu objetivo aquí: </span>
          <input
            type="number"
            pattern="^\$\d{1,3}(,\d{3})*(\.\d+)?$"
            data-type="currency"
            class="input--foundationGoal"
            (change)="setFoundationGoal($event.target.value)"
            [ngClass]="{ 'input-failed': foundationGoalError }"
          />
          <label style="padding-left: 1em">{{ foundationCurrency }} </label>
        </div>
        <select (change)="setFoundationCurrency($event.target.value)" style="width: 5em">
          <option>COP</option>
          <option>USD</option>
        </select>
      </div>

      <br />

      <div class="input--row x1">
        <div class="wrapper" style="padding: 0.5em 1em; border-bottom: 1px solid gray">
          <span style="padding-right: 1em"> Tiempo de recaudo: </span>
          <input
            type="text"
            disabled
            class="input--foundationDeadline"
            [value]="getFoundationDeadline"
            [ngClass]="{ 'input-failed': foundationDeadlineError }"
          />
        </div>
        <select (change)="setFoundationDeadline($event.target.value)" style="width: 8em">
          <option value="1">1 dia</option>
          <option value="3">3 dias</option>
          <option value="7">1 semana</option>
          <option value="14">2 semanas</option>
          <option value="21">3 semanas</option>
          <option value="30">1 mes</option>
          <option value="60">2 meses</option>
          <option value="90">3 meses</option>
          <option value="120">4 meses</option>
        </select>
      </div>
    </div>

    <br />
    <br />

    <div class="form-row" style="width: 100%">
      <ui-solid-btn
        style="margin: 0 auto"
        [color]="'red'"
        [minWidth]="'200px'"
        [margins]="'0em auto'"
        [paddings]="'0.5em 1em'"
        [text]="'Enviar'"
        (nClick)="addCampaingFoundation()"
      ></ui-solid-btn>
    </div>
  </div>

  <div class="form--footer">
    <div class="row more-info">
      <h5>
        Para saber más sobre nuestras políticas y funcionamiento puedes hacer
        <a href="">clic aqui</a>
      </h5>
    </div>
  </div>
</form>
