<div class="card-campaign"
[ngClass]="{
  'theme': theme,
  'theme--in-process': theme === IStatusEnum.S0_IN_PROCESS,
  'theme--pending': theme === IStatusEnum.S1_IN_PENDING,
  'theme--correcting': theme === IStatusEnum.S2_IN_CORRECTING,
  'theme--published': theme === IStatusEnum.S3_PUBLISHED,
  'theme--collecting': theme === IStatusEnum.S4_IN_COLLECTING,
  'theme--pause': theme === IStatusEnum.S5_IN_PAUSE,
  'theme--executing': theme === IStatusEnum.S6_IN_EXECUTION,
  'theme--finsihed': theme === IStatusEnum.S8_FINISHED,
  'theme--expired': theme === IStatusEnum.S9_EXPIRED
}">

  <div class="card-campaign--wrapper">
    <div class="card-campaign--image" (click)="goToCampaing($event)" [ngClass]="{ active: hasPhoto(campaign.photos[0]) }">
      <img class="active-photo" [src]="campaign.photos[0]" (load)="onPhotoLoad()" />
      <img class="active-no-photo" src="../../../../../assets/icons/placeholder_photo.png" />
      <img class="active-gradient" src="../../../../../assets/images/bg-gradient.png" />
      <span *ngIf="theme !== IStatusEnum.S8_FINISHED ">{{ campaign.id }} {{ campaign.title | truncateText: 15 }}</span>
    </div>

    <div class="card-campaign--content" style="width: 100%" *ngIf="!tooltipEnabled">

      <div class="card--row">
        <div class="card--col">
          <h1 class="card-title">
            {{ campaign.title | truncateText: 10 }}
          </h1>
        </div>
        <div class="card--col" (mouseenter)="setHover()" (mouseleave)="setHover()">
          <div class="card-like-btn" (click)="setLike()">
            <img *ngIf="!hasLike" src="../../../../../assets/icons/heart.png" />
            <img *ngIf="hasLike" src="../../../../../assets/icons/heart_filled.png" />
          </div>
        </div>
      </div>

      <div class="card--row">
        <div class="card--col">
          <p class="card-descrition">
            {{ description | truncateText: 75 }}
          </p>
        </div>
      </div>

      <div class="card--row" *ngIf="theme !== 'finished'">
        <div class="card--col">
          <h2 class="card-category">
            {{ categoryLabel | translate | categoryToText }}
          </h2>
        </div>
      </div>

      <div class="card--row" *ngIf="theme !== 'finished'">
        <div class="card--col">
          <h3 class="card-donated">{{ campaign.donated | currency: '$':'symbol':'1.0-0' }} recolectados</h3>
        </div>
        <div class="card--col">
          <div class="card-star-btn">
            <img src="../../../../../assets/icons/star.png" />
          </div>
        </div>
      </div>
      <div class="card--row" style="width: 100%" *ngIf="theme !== 'finished'">
        <div class="card--col" style="width: 100%">
          <div class="card-donated-level" style="width: 100%">
            <ui-widget-percentage-level [currentValue]="campaign.donated || 0" [maxValue]="campaign.goal"></ui-widget-percentage-level>
          </div>
        </div>
      </div>

      <div class="card--row" *ngIf="(timeLeft && timeLeft.value > 0)" style="padding-top: 1em;">
        <div class="card--col card-days-left">
          <img src="../../../../../assets/icons/RELOJ.png" style="padding-right: 10px; width: 30px" />
          <h2 *ngIf="timeLeft.value > 0">
            {{ timeLeft.value }}
            {{ 'campaings.timeLeft.' + timeLeft.label | translate }}
            {{ 'campaings.timeLeft.toFinish' | translate }}
          </h2>
          <h2 *ngIf="timeLeft.value <= 0"></h2>
        </div>
      </div>
      <div class="card--row" *ngIf="theme === 'finished'">
        <div class="card--col" style="padding-top: 42px;">
          <h5 class="theme--finished__donated">{{ campaign.donated | currency: '$':'symbol':'1.0-0'   }} recolectado</h5>
          <h5 class="theme--finished__donors">{{ campaign.donors.length}} donantes</h5>
        </div>
        <div class="card--col"><img src="../../../../../assets/cards/award.png"></div>
      </div>
    </div>

    <div class="card-campaign--content follow-style" style="width: 100%" *ngIf="tooltipEnabled">

      <div class="card--row">
        <div class="card--col">
          <h1 class="card-title">
            {{ campaign.title | truncateText: 10 }}
          </h1>
        </div>
        <div class="card--col" (mouseenter)="setHover()" (mouseleave)="setHover()">
          <div class="card-like-btn" (click)="setLike()">
            <img *ngIf="!hasLike" src="../../../../../assets/icons/heart.png" />
            <img *ngIf="hasLike" src="../../../../../assets/icons/heart_filled.png" />
          </div>
        </div>
      </div>

      <div class="card--row follow-style">
        <div class="card--col" style="width: 100%; display: block">
          <div class="" >
            <h1>Para seguir campañas por favor inicia sesión o regístrate: </h1>
            <h2><a (click)="goToSignIn()"> INICIAR SESIÓN </a></h2>
            <h3>No tienes una cuenta,</h3>
            <h4><a>regístrate aquí</a></h4>
          </div>
        </div>
      </div>
    </div>


  </div>


  <div class="message__theme" *ngIf="theme && campaign.status" (click)="goToCampaing($event)" >
    <label><span>{{ campaign.status | campaignStatusLabel }}</span></label>
  </div>

  <div class="container__executing" *ngIf="theme && theme === IStatusEnum.S6_IN_EXECUTION" (click)="goToCampaing($event)" >
    <img classs="lupa" src="../../../../../assets/cards/lupa.png">
    <h1 class="title"> ¡Cerca de una campaña exitosa! </h1>
    <img class="awar" src="../../../../../assets/cards/award.png">
    <div class="bubble">
      <p>
        Una vez los fondos recaudados cumplan su destino el estado de tu causa pasará a ser una campaña exitosa.
      </p>
    </div>
  </div>

</div>
