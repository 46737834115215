import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgProgressComponent } from 'ngx-progressbar';
import { ToastrService } from 'ngx-toastr';
import { ICampaign } from 'src/app/core/campaigns/models/campaign.model';
import { CampaignsService } from 'src/app/core/campaigns/services/campaigns.service';
import { IUser } from 'src/app/core/users/models/user.model';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'ui-epayco-btn',
  templateUrl: './epayco-btn.component.html',
  styleUrls: ['./epayco-btn.component.less']
})
export class EpaycoBtnComponent implements OnInit {

  constructor(private $http: HttpClient, private $campaigns: CampaignsService, private toastr: ToastrService) {}

  @Input()
  disabled = false;

  @Input()
  selected = false;

  @Input()
  settings: any = {};

  @Output()
  nSucced = new EventEmitter();

  @Output()
  nSelect = new EventEmitter();

  @ViewChild(NgProgressComponent) progressBar: NgProgressComponent;

  @Input()
  amount: number;

  @Input()
  campaign: ICampaign;

  @Input()
  user: IUser;

  data ={

    //Parametros compra (obligatorio)
    name: "PRODUCT_NAME",
    description: "PRODUCT_DESCRIPTION",
    invoice: "1234",
    currency: "cop",
    amount: "0",
    tax_base: "0",
    tax: "0",
    country: "co",
    lang: "es",

    Onpage:true,
    //external: "true",


    //Atributos opcionales
    extra1: "extra1",
    extra2: "extra2",
    extra3: "extra3",

    // Endpoint de laravel POST, x-, x-,
    confirmation:  `${environment.server}/payments/handler/epayco`,

    // Redirection, para mensaje de pago exitoso, etc.
    // response: '',

    //Atributos cliente
    name_billing: "FundSoliun",
    address_billing: "Carrera 19 numero 14 91",
    type_doc_billing: "cc",
    mobilephone_billing: "3050000000",
    number_doc_billing: "100000000",

    acepted:"",
    rejected:"",
    pending:"",

    }

  ngOnInit(): void {
  }

  public async onSubmit() {

    this.data.name = this.campaign.title;
    this.data.description = this.campaign.id + '';
    this.data.invoice = this.campaign.id + '';
    this.data.amount = this.amount + '';

    this.data.name_billing = this.user.name ||  '';
    this.data.address_billing = this.user.address ||  '';
    this.data.type_doc_billing = this.user.nid_type ||  'CC';
    this.data.mobilephone_billing = this.user.phone ||  '';
    this.data.number_doc_billing = this.user.nid_number ||  '';

    const url = `${environment.server}/payments`;
    const obj = {
      ...this.user,
      user_id: this.user.id || -1,
      is_anonimous: (this.user && this.user.id && this.user.email) ? false : true,
      campaign_id: this.campaign.id,
      method: 'CREDIT_CARD',
      amount: this.amount,
      currency: 'COP',
      status: 'PENDING',
      nid_number: this.user.nid_number,
      email: this.user.email,
      name: this.user.firstname + ' ' + this.user.lastname,
      password: '2022'
    };

    const responsePayment: any = await this.$http.post<any>(url, obj).toPromise();
    const responsePaymentId = responsePayment.id;
    this.data.invoice = responsePaymentId;
    this.data.acepted =  `${environment.url}/campaing/${this.campaign.id}/modal/payment/ACCEPTED`;
    this.data.rejected =  `${environment.url}/campaing/${this.campaign.id}/modal/payment/REJECTED`;
    this.data.pending =  `${environment.url}/campaing/${this.campaign.id}/modal/payment/PENDING`;

    var handler = (window as any).ePayco.checkout.configure({
      key: '6e954af29476c4439bffe90adfbe16a0',
      test: true
    });

    console.log('epayco::daata')
    console.log(this.data)

    console.log('epayco::obj')
    console.log(obj)

    if(!this.disabled){
      handler.open(this.data)
    } else {
      this.toastr.error('DONACIÓN ERROR', 'ingresa un valor')
    }

  }

  public doSelectEPayCo(){

  }

}
