import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { SwiperComponent, SwiperConfigInterface, SwiperDirective } from 'ngx-swiper-wrapper';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { HttpClient } from '@angular/common/http';
import { PicturesService } from 'src/app/shared/services/pictures.service';

@Component({
  selector: 'ui-photo-gallery',
  templateUrl: './photo-gallery.component.html',
  styleUrls: ['./photo-gallery.component.less'],
})
export class PhotoGalleryComponent implements OnInit {
  @Input()
  photos: string[];

  @Input()
  editable: boolean;

  @Output()
  nChange = new EventEmitter();

  @Output()
  nDelete = new EventEmitter();

  @ViewChild(SwiperComponent, { static: false }) componentRef?: SwiperComponent;
  @ViewChild(SwiperDirective, { static: false }) directiveRef?: SwiperDirective;

  @Input()
  isBulletsVertical = false;

  files: File[] = [];

  public config: SwiperConfigInterface = {
    direction: this.isBulletsVertical ? 'vertical' : 'horizontal',
    slidesPerView: 1,
    scrollbar: false,
    navigation: false,
    autoplay: true,
    effect: 'fade',
    speed: 1000,
    loop: true,
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
      hideOnClick: false,
    },
  };

  constructor(private http: HttpClient, private $pictures: PicturesService) {}

  ngOnInit(): void {}

  public get activePhotos(): string[]{
    return this.photos.filter(item => item != "null");
  }

  delete(photo_src: string) {

    const photos = JSON.parse(JSON.stringify(this.photos));
    const photoIndex = photos.findIndex((photo) => photo === photo_src);
    photos[photoIndex] = null;

    this.nChange.emit(
      photos.map( item => {
        return {
          src: item + '',
          file: null
        }
      })
    );
  }

  hasPhoto(photo) {
    return photo && !photo.includes('null');
  }

  setFile($event) {
    const file = $event.target.files[0];
    const fr = new FileReader();
    console.log(' ')
    console.log(' setFile($event) ')
    console.log(file)
    console.log(' ')
    console.log(' this.photos 1')
    console.log(this.photos)
    console.log(' ')
    let _photos = this.photos.map( src => { return { src: src, file: null} });
    let photos = JSON.parse(JSON.stringify([..._photos]) + '');
    fr.onload = async (e) => {
      if (!this.hasPhoto(this.photos[0])) {
        const url0 = await this.$pictures.upload(file);
        photos[0].src = url0;
        photos[0].file = file;
      } else if (!this.hasPhoto(this.photos[1])) {
        const url1 = await this.$pictures.upload(file);
        photos[1].src = url1;
        photos[1].file = file;
      } else if (!this.hasPhoto(this.photos[2])) {
        const url2 = await this.$pictures.upload(file);
        photos[2].src = url2;
        photos[2].file = file;
      } else if (!this.hasPhoto(this.photos[3])) {
        const url3 = await this.$pictures.upload(file);
        photos[3].src = url3;
        photos[3].file = file;
      } else {
        console.log('FULL');
      }

      console.log(' ')
      console.log(' photos ')
      console.log(photos)
      console.log(' ')

      this.nChange.emit(photos);
    };
    fr.readAsDataURL(file);
  }

  listOrderChanged($event) {
    console.log('listOrderChanged');
    console.log($event);
  }

  onDragula($event){
    this.nChange.emit($event.map( item => {
      return {
        src: item,
        file: null
      }
    })
    );
  }


  isVerticalPhoto(photo){
    const img = document.createElement("img");
    img.src = photo;
    const h = img.naturalHeight;
    const w = img.naturalWidth;
    return (h > w);
  }
}
