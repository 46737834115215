import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { ICampaign } from '../models/campaign.model';
import { IStatusValues } from '../models/status.model';
@Pipe({
  name: 'filterByIsActive',
})
export class FilterByIsActivePipe implements PipeTransform {
  transform(campaigns: ICampaign[]): ICampaign[] {
    if(!campaigns) return [];
    return campaigns.filter((campaign) =>
    (
      campaign.status === 'OPEN' ||
      campaign.status === 'COLLECTING' ||
      campaign.status ===  'S3_PUBLISHED' ||
      campaign.status ===  'S4_IN_COLLECTING' ||
      //campaign.status ===  'S5_IN_PAUSE' ||
      campaign.status ===  'S6_IN_EXECUTION'
    )
      &&
      (
        true && isCampaignUndated(campaign)
      )
    );
  }
}


export function isCampaignUndated(campaign: ICampaign): boolean{
  const days = moment.utc(campaign.date_end).diff(moment.utc(), 'days');
  return days > 0;
}
