import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'ui-text-input-inline',
  templateUrl: './text-input-inline.component.html',
  styleUrls: ['./text-input-inline.component.less'],
})
export class TextInputInlineComponent implements OnInit {
  @Input()
  type: string;

  @Input()
  label: string;

  @Input()
  width: string;

  @Input()
  border: boolean;

  @Input()
  placeholder: string;

  @Input()
  value: string;

  @Input()
  error: any;

  @Input()
  errorValue: boolean;

  @Input()
  errorText: string;

  @Input()
  errorFn = (value, value2?) => {};

  @Input()
  colorCian: boolean;

  @Output()
  nChange = new EventEmitter();

  isFocus = false;
  text = '';
  empty = true;

  constructor(private _cdRef: ChangeDetectorRef) {}

  ngOnInit(): void {}

  onFocus() {
    this.isFocus = true;
  }
  offFocus() {
    this.isFocus = false;
  }
  setText($event) {
    this.empty = false;
    this.text = $event.target.value;
    this.nChange.emit($event);
  }
  isError(){
    return !this.empty && (this.error || this.errorValue || this.errorFn(this.text));
  }
}
