import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { IServerUser, IUser } from '../models/user.model';

const admins = ['wallamejorge@hotmail.com','liudezain@gmail.com', 'civil.delgado@hotmail.com'];

@Injectable({
  providedIn: 'root',
})
export class UserService {

  private _user = new BehaviorSubject<IUser>(null);
  public user$ = this._user.asObservable();

  private _users = new BehaviorSubject<IUser[]>([]);
  public users$ = this._users.asObservable();

  public role$ = this.user$.pipe(map((user: IUser) => user.rol));
  public isAdmin$ = this.user$.pipe(map((user: IUser) => { 
    if(user.rol === 'ADMIN') return true;
    if(admins.includes(user.email)) return true;
    return false;
  }));

  constructor(private $http: HttpClient) {}

  isAdmin(){
    if(this._user.getValue().rol === 'ADMIN') return true;
    if(admins.includes(this._user.getValue().email)) return true;
    return false;
  }
  async init(id: number){
    let user = JSON.parse(sessionStorage.getItem('user'));
    let userId;
    if (!user) {
      userId = id
      user = await this.getUserById(id);
    } else {
      userId = user.id;
    }
    this.setUser(user);
    return user;
  }

  setUser(user: IUser) {
    sessionStorage.setItem('user', JSON.stringify(user))
    this._user.next(user);
  }

  public getUser() {
    return this._user.getValue();
  }

  public async getUserById(id) {
    return this.$http.get<IServerUser>(`${environment.server}/users/${id}`).toPromise();
  }

  syncUser() {
    return this.user$;
  }

  public async getAll(){
    const users = await this.$http.get<IServerUser[]>(`${environment.server}/users`).toPromise();
    this._users.next(users);
    return users;
  }

  public syncUsers() {
    return this.users$;
  }



  public async patchUserById(id: string, payload: IUser) {
    console.error('  patchUserById(id: string, payload: IUser) ')
    console.error(payload)
    return this.$http.patch<IServerUser>(`${environment.server}/users/${id}`, {...payload}).toPromise();
  }



  
}
