<div class="gallery" style="height: 600px; overflow: hidden">
  <div class="gallery-wrapper" style="height: 600px; overflow: hidden">

    <swiper
      *ngIf="!editable"
      class="photo-galler--swiper"
      [config]="config"
      style="height: 600px; overflow: hidden"
      [direction]="isBulletsVertical ? 'vertical' : 'horizontal'"
    >
      <div class="photo-gallery--slide" *ngFor="let photo of activePhotos">
        
        <div *ngIf="!isVerticalPhoto(photo)">
          <img *ngIf="hasPhoto(photo)" [defaultImage]="photo" [lazyLoad]="photo" [useSrcset]="true" />
          <img *ngIf="!hasPhoto(photo)" src="../../../../../assets/icons/placeholder_photo.png" />
        </div>

        <div class="isVertical" *ngIf="isVerticalPhoto(photo)">
          <img class="bg" *ngIf="hasPhoto(photo)" [defaultImage]="photo" [lazyLoad]="photo" [useSrcset]="true" />
          <img class="front" *ngIf="hasPhoto(photo)" [defaultImage]="photo" [lazyLoad]="photo" [useSrcset]="true" />
          <img *ngIf="!hasPhoto(photo)" src="../../../../../assets/icons/placeholder_photo.png" />
        </div>

      </div>
    </swiper>

<!--
<ngx-sortable [items]="photos" [name]="'List'" (listSorted)="listOrderChanged($event)">
      <ng-template let-item>
          <div class="sortable-list-item">
              {{item}}
          </div>
      </ng-template>
  </ngx-sortable>
-->
    <div class="editable" *ngIf="editable">
      <div class="col first-col">
        <input type="file" name="file" id="file" class="inputfile" (change)="setFile($event)" />
        <label for="file"><img src="../../../../../assets/icons/photo_upload_tutorial.PNG" /></label>
      </div>



      <div class="col last-col"   [dragula]="'PHOTOS'" [dragulaModel]="photos" (dragulaModelChange)="onDragula($event)">
        <div class="photo_frame pk" *ngFor="let photo of photos" style="position: relative">
          <span
            (click)="delete(photo)"
            style="
              font-weight: 900;
              position: absolute;
              cursor: pointer;
              top: 15px;
              right: 10px;
              background-color: #e0404d;
              padding: 0.35em;
              border-radius: 100%;
              height: 1em;
              width: 1em;
              text-align: center;
              font-family: 'POPPINS-REGULAR';
              color: #eafdf7;
              font-size: 18px;
            "
            >x</span
          >
          <img *ngIf="hasPhoto(photo)" [src]="photo" />
          <img *ngIf="!hasPhoto(photo)" src="../../../../../assets/icons/placeholder_photo.png" />
        </div>
      </div>
    </div>
  </div>
</div>
