import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Profile2PagesComponent } from './profile2-pages.component';
import { Profile2PagesRoutingModule } from './profile2-pages.routing';
import { Profile2CampaignsLikedViewComponent } from './views/profile2-campaigns-liked-view/profile2-campaigns-liked-view.component';
import { Profile2CampaignsDonatedViewComponent } from './views/profile2-campaigns-donated-view/profile2-campaigns-donated-view.component';
import { Profile2CampaignsOrganizedViewComponent } from './views/profile2-campaigns-organized-view/profile2-campaigns-organized-view.component';
import { CoreModule } from 'src/app/core/core.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { ProfileFilters2Component } from './components/profile-filters/profile-filters.component';
import { ProfileTabsUser2Component } from './components/profile-tabs-user/profile-tabs-user.component';

@NgModule({
  declarations: [
    Profile2PagesComponent,
    ProfileFilters2Component,
    ProfileTabsUser2Component,
    Profile2CampaignsLikedViewComponent,
    Profile2CampaignsDonatedViewComponent,
    Profile2CampaignsOrganizedViewComponent,
  ],
  exports: [ProfileFilters2Component],
  imports: [CommonModule, SharedModule, CoreModule, Profile2PagesRoutingModule],
})
export class Profile2PagesModule {}
