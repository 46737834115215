<div class="page-checkout">
  <section class="page-section campaing-wrapper" *ngIf="campaign">
    <div class="wrapper">
      <div class="col">
        <form [formGroup]="checkFormP">
          <h1 class="checkout-form--title">SELECCIONA EL MONTO A DONAR v1</h1>

          <div class="form-row">
            <div class="form-col to-center" style="margin-top: -40px">
              <br />
              <ui-dropdown-input-c [options]="['COP', 'USD', 'EUR']" (nChange)="setCurrency($event)" [border]="true" [label]="coin || 'Moneda'">
              </ui-dropdown-input-c>
            </div>
            <ui-outline-btn
              [active]="donationButtonActive === '10000'"
              [color]="'gray'"
              [margins]="'0em'"
              [paddings]="'0.5em'"
              [minWidth]="'180px'"
              [text]="getFirstDonationButton()"
              [pipe]="'currency'"
              [currency]="currency"
              (nClick)="setAmout(10000)"
              style="margin-left: 60px"
            >
            </ui-outline-btn>

            <img src="../../../../../assets/icons/heart_filled.png" style="width: 16px" />

            <ui-outline-btn
              [active]="donationButtonActive === '15000'"
              [color]="'gray'"
              [margins]="'0em'"
              [paddings]="'0.5em'"
              [minWidth]="'180px'"
              [text]="getSecondDonationButton()"
              [pipe]="'currency'"
              [currency]="currency"
              (nClick)="setAmout(15000)"
            >
            </ui-outline-btn>

            <img src="../../../../../assets/icons/heart_filled.png" style="width: 16px" />

            <ui-outline-btn
              [active]="donationButtonActive === '20000'"
              [color]="'gray'"
              [margins]="'0em'"
              [paddings]="'0.5em'"
              [minWidth]="'180px'"
              [text]="getThirdDonationButton()"
              [pipe]="'currency'"
              [currency]="currency"
              (nClick)="setAmout(20000)"
            >
            </ui-outline-btn>
          </div>

          <br />

          <div class="form-row">
            <div class="form-col" style="width: 25pc">
              <div class="form-row to-left" style="border-bottom: 1px solid gray; color: #575756">
                <label style="padding-right: 0.5em">Tu donación : </label>
                <ui-text-input
                  [type]="'currency'"
                  [width]="'auto'"
                  [value]="amount || '0.00'"
                  [error]="getErrorByFieldName('amount')"
                  (nChange)="setAmout($event, 'text-input')"
                  style="width: 10px"
                >
                </ui-text-input>
                <span style="margin-left: auto">{{ currency }}</span>
              </div>
            </div>
            <div class="col">
              <div class="row">
                <h5 style="margin-top: 50px; font-size: 16px; line-height: 150%; width: 33pc; margin-left: 28px">
                  Puedes seleccionar uno de los montos por defecto o escribir el tuyo de forma libre (Máximo $1.000.000 COP / $260 USD por día).
                </h5>
              </div>
            </div>

            <div class="form-col currency-col"></div>
          </div>

          <div class="form-row">
            <h2 class="checkout-form--title">TUS DATOS </h2>
          </div>

          <div class="form-row">
            <ui-place-input style="width: 100%" 
            (nChange)="setPlace( $event)"
            ></ui-place-input>
          </div>

          <br />

          <div class="form-row">
            <div class="form-user-type" *ngIf="country === 'Colombia'">
              <label class="first-label" (click)="userType = 'person'" [ngClass]="{ active: userType === 'person' }"> PERSONA NATURAL </label>
              <label class="last-label" (click)="userType = 'company'" [ngClass]="{ active: userType === 'company' }"> PERSONA JURIDICA (EMPRESA) </label>
            </div>
            <div class="form-user-type" *ngIf="country != 'Colombia'">
              <label class="first-label" (click)="userType = 'person'" [ngClass]="{ active: userType === 'person' }"> PERSONA NATURAL </label>
              <label class="last-label disabled" style="opacity: 0.6" [ngClass]="{ active: userType === 'company' }"> PERSONA JURIDICA (EMPRESA) </label>
            </div>
          </div>



          <div class="form-row">
            <ui-text-input-inline-c
              *ngIf="userType == 'person'"
              (nChange)="setUserFirstName($event)"
              [error]="getErrorByFieldName('username')"
              [border]="true"
              [label]="'Nombre'"
              [placeholder]="user?.firstname || ''"
              [type]="'nombre'"
              [colorCian]="true"
              style="width: 48%"
            ></ui-text-input-inline-c>

            <ui-text-input-inline-c
              *ngIf="userType == 'person'"
              (nChange)="setUserLastName($event)"
              [error]="getErrorByFieldName('lastname')"
              [border]="true"
              [label]="'Apellido'"
              [placeholder]="user?.lastname || ''"
              [type]="'text'"
              style="width: 48%"
            ></ui-text-input-inline-c>

            <ui-text-input
              *ngIf="userType == 'company'"
              [error]="getErrorByFieldName('company')"
              [placeholder]="companyName || 'Razón Social'"
              [border]="true"
              style="width: 100%"
              (nChange)="setCompanyName($event)"
            ></ui-text-input>
          </div>

          <div class="form-row hide-name-row">
            <input type="checkbox" />
            <label> Ocultar el nombre y comentarios menos del organizador y equipo </label>
          </div>

          <!-- <div class="form-row">
            <label *ngIf="userType == 'person'">Documento de Identidad (CC, TI, CE, ...):</label>
            <label *ngIf="userType == 'company'">Número de registro tributario / NIT:</label>
          </div> -->
          <div class="form-row" *ngIf="userType == 'person'" style="-webkit-align-items: flex-start; -ms-flex-align: flex-start; align-items: flex-start;">
            <div class="form-col" style="width: 50%; box-sizing: border-box; padding-right: 0.5em; ">
              <ui-dropdown-input-c [default]="cc_type" [options]="cc_types" (nChange)="setCCType($event)" [border]="true" [label]="type || 'Tipo de documento'">
              </ui-dropdown-input-c>
            </div>
            <div class="form-col" style="width: calc(50% - 0.5em); padding-left: 0.5em; margin-top: 0px">
              <ui-text-input-inline-c
                (nChange)="setCC($event)"
                [error]="getErrorByFieldName('cc')"
                [border]="true"
                [label]="'Numero de documento'"
                [placeholder]="user?.nid_number || ''"
                style="width: 48%"
              ></ui-text-input-inline-c>
            </div>
          </div>

          <div class="form-row" *ngIf="userType == 'company'">
            <div class="form-col" style="width: calc(100% - 0em); padding-left: 0.5em">
              <ui-text-input [placeholder]="cc || 'NIT'" [border]="true" style="width: 50%" (nChange)="setCC($event)"> </ui-text-input>
            </div>
          </div>

          <div class="form-row">
            <div class="form-col" style="width: 49%; box-sizing: border-box; padding-right: 0.5em">
              <ui-text-input-inline-c
                (nChange)="setEmail($event)"
                [error]="getErrorByFieldName('email')"
                [border]="true"
                [label]="'Correo electrónico'"
                [placeholder]="user?.email || ''"
                style="width: 48%"
              ></ui-text-input-inline-c>
            </div>
            <div class="form-col" style="width: 50%; box-sizing: border-box; padding-left: 0.5em">
              <ui-text-input-inline-c
                (nChange)="setPhoneNumber($event)"
                [error]="getErrorByFieldName('phone')"
                [border]="true"
                [label]="'Numero de Télefono'"
                [placeholder]="user?.phone || ''"
                style="width: 48%"
              ></ui-text-input-inline-c>
            </div>
          </div>

          <div class="form-row" style=" -ms-flex-align: flex-start; align-items: flex-start;">
            <div class="form-col" style="width: 50%; box-sizing: border-box; padding-right: 0.5em">
              <ui-dropdown-input-c
                [options]="['Facebook', 'Directamente en el Sitio web', 'Recomendado']"
                (nChange)="setOrigin($event)"
                style="width: 50%"
                [border]="true"
                [label]="How || '¿Cómo te enteraste?(Opcional)'"
              ></ui-dropdown-input-c>
            </div>
            <div class="form-col" style="width: 50%; box-sizing: border-box; padding-left: 0.5em; margin-top: 0px">
              <ui-text-input-inline-c
                (nChange)="setPostCode($event)"
                [error]="getErrorByFieldName('postcode')"
                [border]="true"
                [placeholder]="''"
                [label]="'Codigo postal(Opcional)'"
                style="width: 48%"
              ></ui-text-input-inline-c>
            </div>
          </div>

          <div class="form-row" style="justify-content: center; padding-top: 0px;">
            <div class="form-col payu-col">
              <ui-epayco-btn
              [disabled]="!((!!amount) && (amount > 1))"
              [amount]="amount"
              [campaign]="campaign"
              [user]="user"
              [selected]="payBtnSelected === 'WOMPI'"
              (nSelect)="onEpayCoSelect()">
              </ui-epayco-btn>
            </div>

            <div class="form-col paypal-col" *ngIf="false">
              <ui-paypal-btn [selected]="payBtnSelected === 'PAYPAL'" (nSelect)="onPayPalSelect($event)"> </ui-paypal-btn>
              <p style="width: auto">Con PayPal puedes realizar tu donación a través de tarjetas crédito y saldo en la cuenta.</p>
              <img
                src="../../../assets/images/credit-cards-icon2.png"
                style="margin: 0 auto; display: block; width: fit-content; margin-left: auto; margin-right: 0px"
              />
            </div>

          </div>
        </form>
      </div>

      <div class="col">
        <ui-campaign-info
          [campaign]="campaign"
          [title]="campaign.title"
          [photo]="campaign.organizer.photo_url"
          [company]="''"
          [name]="campaign.organizer.name"
          [position]="campaign.organizer.position"
          [category]="campaign.category"
          [donated]="campaign.donated"
          [goal]="campaign.goal"
          [like]="false"
          [start]="campaign.date_start"
          [end]="campaign.date_end"
        ></ui-campaign-info>
        <div class="campaign-buttons">
          <div class="wrapper x-extremes y-center">
            <ui-outline-btn 
            style="width:100%"
            [color]="'red'" 
            [margins]="'0em'" 
            [paddings]="'1em'" [minWidth]="'100%'" [text]="'campaigns.share'" (nClick)="openModal()">
            </ui-outline-btn>
          </div>
        </div>
        <ui-campaign-donors 
        [id]="campaign.id"
        [total]="campaign.stats.total" 
        [last24hrs]="campaign.stats.last24hrs"
        > </ui-campaign-donors>
        <ui-campaign-map [place]="campaign.place"></ui-campaign-map>
        <div class="campaing-about-us x-extremes y-center">
          <h2 class="campaing-about-us--how">
            {{ 'campaigns.how_we_work' | translate }}
          </h2>
          <h2 class="campaing-about-us--report x-end y-center">
            <img src="../../../assets/icons/flag.png" />
            <a>
              {{ 'campaigns.report' | translate }}
            </a>
          </h2>
        </div>
      </div>
    </div>
  </section>

  <section class="page-section faq">
    <div class="faq--wrapper">
      <ul>
        <li>
          <strong>La unidad como formula de crecimiento.</strong> Creemos en la colaboración con otras organizaciones e instituciones para potenciar sinergias y
          mejorar el rendimiento del trabajo de la fundación.
        </li>
        <li>
          <strong>Nuestro compromiso social es</strong> trabajar con población que se encuentra en situaciones de mayor vulnerabilidad, con los procesos de
          integración intercultural y deportiva.
        </li>
        <li>
          <strong>La confianza es importante.</strong> Generamos relaciones a nivel interno y externo bajo dinámicas fluidas y eficaces promoviendo la
          comunicación asertiva.
        </li>
      </ul>
      <div class="line"></div>
    </div>
  </section>

  <ui-share-modal *ngIf="isModal" (nClose)="closeModal()" [campaign]="campaign"></ui-share-modal>
  <ui-success-modal *ngIf="isModalSuccess" (nClose)="closeModal()"></ui-success-modal>
</div>
