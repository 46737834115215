<div class="page-about page-about-work">
  <section class="section-title">
    <h1>¿CÓMO FUNCIONAMOS?</h1>
  </section>

  <section class="section-green">
    <br />
    <h2>PROPUESTA DE VALOR:</h2>
    <br />
    <ul>
      <li>
        <h3>¿Qué valor estamos ofreciendo a los clientes?</h3>
        <br />
        <p>Ofrecemos una solución diferente, a la medida de cada tipo de cliente.</p>
        <p>
          <strong>Usuarios:</strong> A los donantes se les da la posibilidad de encontrar entre una gran variedad de fundaciones y causas, el proyecto social
          que desea apoyar en un solo lugar, además se le da la tranquilidad de que la FUNDACIÓN SOLIUN estará como fiduciaria de los recursos que ellos donen a
          los proyectos sociales, por lo que tendrá las garantías de que su dinero se invierte efectivamente en lo que ellos quieren apoyar. <br /><br />
          <strong>Fundaciones:</strong> Para los donatarios en este caso fundaciones, tenemos a disposición una plataforma en la que pueden aumentar el flujo de
          ingresos por donaciones, y exposición de marca, además tendrán la posibilidad de recibir las donaciones de manera virtual y contar con un medio
          digital de gran flujo de usuarios dispuestos a donar. <br /><br />
          <strong>Organizadores:</strong> al igual que las fundaciones, se entregará un espacio para cualquier persona que desee ayudar o necesite ayuda de
          manera casi inmediata, podrá estar en una plataforma con usuarios dispuestos a donar y podrán logran recaudación de fondos de manera virtual y con
          mejor exposición.
        </p>
      </li>
      <li>
        <h3>¿Qué problema solucionamos?</h3>
        <br />
        <p>
          Las fundaciones y causas no han llegado completamente a la era digital, dado a que muchos factores que intervienen en su desarrollo no se
          han logrado unificar, es decir, en la actualidad no existe un espacio virtual que una a todas las personas que quieren donar, con las fundaciones o
          causas que necesiten donantes. Las donaciones se realizan de una manera algo informal, y con un toque frío, es decir hay muy poca conexión entre los
          donantes y los donatarios, por tal razón hemos decidido iniciar una plataforma que permite reunir en un mismo lugar a todos los involucrados en los
          proyectos sociales que mejoran la calidad de vida de la humanidad, para asegurarnos de la interacción entre los involucrados y podamos generar más
          donaciones que lleguen a los que realmente lo necesitan.
        </p>
      </li>
      <li>
        <h3>¿Qué necesidad cubrimos?</h3>
        <br />
        <p>
          Particularmente estamos apoyando al donatario a recibir más donaciones y exponer su marca y la de los donantes junto con una variedad de opciones
          confiables y seguras para generar tranquilidad, tanto de inversión a la hora de realizar una donación como de recibir sin problema el dinero
          recaudado.
        </p>
      </li>
    </ul>
  </section>

  <section class="section-pink">
    <h3>Una vez demostrada nuestra propuesta de Valor y la importancia de la plataforma esta es la forma en la que funcionamos:</h3>
    <br />
    <p>
      Debido a que la fundación debe ser financieramente sostenible, es necesario generar fuentes de ingreso que así como permiten la posibilidad de que otras
      fundaciones o causas lo sean, nosotros también lo seamos. Razón por la cual queremos que cada uno de nuestros usuarios, tengan claridad sobre la forma en
      la que recibimos ingresos.
      <br />
      <br />

      <strong>
        Porcentaje de sostenimiento (Porcentaje de donación): para empezar y a pesar de que la fundación es una entidad sin ánimo de lucro, la plataforma y la
        fundación acarrean costos mensuales que deben ser cubiertos, razón por la cual para el mantenimiento y correcto funcionamiento tanto de la plataforma
        como de la fundación se cobra un porcentaje de la donación que reciba cada fundación o causa, que como se mencionó anteriormente es destinado al
        sostenimiento y funcionamiento tanto de la plataforma como de la fundación.</strong
      >
      <br />
      <br />
      El porcentaje que cobra la fundación por el uso de la plataforma depende de varios factores como lo son el número de donaciones promedio y/o del número de
      usuarios que donen dinero.
      <br />
      <br />
      Iniciando, como entidad recaudaremos el 15% de cada donación, y una vez se logren objetivos de donación mensual y crecimiento de usuarios activos, iremos
      disminuyendo el porcentaje de sostenimiento hasta llegar a porcentajes del 1% o máximo 5%. Por tal razón es indispensable que crezcamos todos, con el fin
      de que la plataforma genere los suficientes ingresos para poder reducir al máximo el porcentaje de sostenimiento. Por este motivo, ya que te interesa
      saber cómo funcionamos y quieres ayudar al mundo te invitamos a realizar donaciones, generar causas si eres una persona o proyectos si eres una fundación
      a través de nuestra plataforma para que así, nuestra misión sea conocida por mas personas y se aceleren los procesos de disminución porcentuales.
      <br />
      <br />
      Proyectos: la fundación a parte de la plataforma tendrá sus propios proyectos sociales en los cuales acudirá a la búsqueda de donaciones, mediante la
      plataforma y mediante otras fuentes de ingreso, de esa manera se contribuirá con el desarrollo de proyectos sociales y adicional con el sostenimiento de
      la fundación.

      <br />
    </p>
  </section>
  <section class="section-blue">
    <br />
    <br />
    <h3>¿CÓMO FUNCIONA LA CREACIÓN DE PROYECTOS Y CAUSAS?</h3>
    <br />
    <p>
      Uno de los valores principales de la fundación es la trasparencia por lo cual buscamos ser lo más abiertos posibles con la información y procesos
      manejados, buscando también que las personas vinculadas a nuestro equipo de trabajo y aliados también tengan dichos principios. Para lograrlo tenemos un
      proceso de validación de todos los proyectos y causas que se quieran incorporar a la plataforma, a continuación, se muestra el paso a paso para que cada
      proyecto o causa sea incorporado a la plataforma y pueda recibir donaciones en esta.
    </p>
    <br />
    <br />

    <img src="assets/icons/campaing-success.png" />

    <br />
    <br />
  </section>
</div>
