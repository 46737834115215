import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import EditorJS from '@editorjs/editorjs';
import Header from '@editorjs/header';
import List from '@editorjs/list';
import Link from '@editorjs/link';

@Component({
  selector: 'ui-text-area-inline',
  templateUrl: './text-area-inline.component.html',
  styleUrls: ['./text-area-inline.component.less'],
})
export class TextAreaInlineComponent implements OnInit, OnDestroy {

  @Input()
  advance: boolean = false;

  @Input()
  type: string;

  @Input()
  label: string;

  @Input()
  width: string;

  @Input()
  border: boolean;

  @Input()
  placeholder: string;

  @Input()
  value: string;

  @Input()
  error: any;

  @Input()
  errorText: any;

  @Input()
  colorCian: boolean;

  @Output()
  nChange = new EventEmitter();

  isFocus = false;

  editor: any = null;

  interval = null;

  constructor() {}

  ngOnInit(): void {
    if(this.advance){
      this.editor = new EditorJS({

        minHeight: 0,

        holderId: 'campaign-description-editor-js',
  
        autofocus: true,
        
        readOnly: false,
  
  
        tools: {
          link: Link,
          header: Header,
          list: List
        },
  
      });



      this.interval = setInterval(() => {
        this.editor.save().then((outputData) => {
          this.nChange.emit({ target: { value: JSON.stringify(outputData)} })
        }).catch((error) => {
          console.log('Saving failed: ', error)
        });
      },2000)


      /*
      this.interval = setInterval(() => {
        this.editor.save().then((outputData) => {
          console.log('outputData')
          console.log(outputData)
          this.nChange.emit(outputData)
        }).catch((error) => {
          console.log('Saving failed: ', error)
        });
        
      }, 1000)
*/

    }
  }
  
  ngOnDestroy(){
    clearInterval(this.interval)
  }

  onFocus() {
    this.isFocus = true;
  }
  offFocus() {
    this.isFocus = false;
  }

  setText($event) {
    this.nChange.emit($event);
  }
}
