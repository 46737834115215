<header>
  <ui-header></ui-header>
</header>

<main>
  <router-outlet></router-outlet>
</main>

<footer>
  <ui-footer></ui-footer>
</footer>

<!-- -->
<!-- -->
<!-- -->
