import { Component, Input, OnInit } from '@angular/core';
import { IUser } from 'src/app/core/users/models/user.model';

@Component({
  selector: 'ui-campaign-last-donors',
  templateUrl: './campaign-last-donors.component.html',
  styleUrls: ['./campaign-last-donors.component.less'],
})
export class CampaignLastDonorsComponent implements OnInit {
  @Input()
  donors: any[];

  constructor() {}

  ngOnInit(): void {}

  public getUserPhoto(user: IUser) {
    return user.photo_url || 'https://ui-avatars.com/api/?background=random&name=' + encodeURI(user.name);
  }
}
