<div class="page signin-page">

  <!-- ------------------------------------------ -->
  <!-- --- AUTH::SIGNIN::FORM ------------------- -->
  <!-- ------------------------------------------ -->
  <form class="form-signin">
    <!-- ---- FORM::HEADER ---------------------- -->
    <div class="form-sigin--header">
      <ui-title-auth-h1 [button]="'x'" [title]="'auth.LOGIN_TITLE'" (nClick)="doClose()"> </ui-title-auth-h1>
    </div>
    <!-- ---- /FORM::HEADER --------------------- -->

    <!-- ---- FORM::CONTAINER ------------------- -->
    <div class="form-sigin--container">

      <!--- -------------------------------- -->
      <!--- -- SOCIAL NETWORK LOGIN -------- -->
      <!--- -------------------------------- -->
      <div class="row social-options" *ngif="false">
        <ui-button-facebook-login></ui-button-facebook-login>
        <ui-button-gmail-login></ui-button-gmail-login>
      </div>
      <!--- -------------------------------- -->

      <div class="row inputs">
        <br />

        <ui-text-input-inline
          (nChange)="setEmail($event)"
          [error]="authError"
          [errorText]="'N/A'"
          [border]="true"
          [label]="'Usuario'"
          [type]="'email'"
          style="width: 100%"
        ></ui-text-input-inline>

        <br />

        <ui-text-input-inline
          (nChange)="setPassword($event)"
          [error]="authError"
          [errorText]="'N/A'"
          [border]="true"
          [label]="'Contraseña'"
          [type]="'password'"
          style="width: 100%"
        ></ui-text-input-inline>

        <span [ngClass]="{ 'input-failed': authError }" *ngIf="authError"> Email o contraseña invalidos. </span>
      </div>

      <div class="row signin-btn">
        <ui-default-button (nClick)="doSignInWithEmailAndPassword()" [label]="'INICIAR SESIÓN'" [color]="'green'"></ui-default-button>
      </div>

      <br />

      <div class="row links">
        <div class="forgot-col">
          <a [routerLink]="'/auth/recovery/password'"> ¿Olvidaste tu contraseña? </a>
        </div>
        <div class="signup-col">
          <span
            id="signup-link-btn"
            >No tienes una cuenta,
            <a [routerLink]="'/auth/signup/alternatives'">registrate aqui</a>
          </span>
        </div>
      </div>

      <br />
    </div>
    <!-- ---- /FORM::CONTAINER ------------------ -->

    <!-- ---- FORM::CONTAINER ------------------- -->
    <div class="form-sigin--footer">
      <div class="row">
        <h5>¿Quieres crear un proyecto como fundación o generar una causa como organizador?</h5>
      </div>

      <div class="row type">
        <a class="foundation" [routerLink]="'/create/campaign/type/foundation'">SOY UNA FUNDACIÓN</a>
        <a class="project" [routerLink]="'/create/campaign/type/cause'">SOY UN ORGANIZADOR</a>
      </div>
      <br />
      <div class="row more-info">
        <h5>
          Para saber más sobre nuestras políticas y funcionamiento puedes hacer
          <a href="">clic aqui</a>
        </h5>
      </div>
      <br />
    </div>
    <!-- ---- /FORM::CONTAINER ------------------ -->
  </form>
</div>
