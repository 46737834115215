import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { ICampaign } from 'src/app/core/campaigns/models/campaign.model';
import { CampaignsService } from 'src/app/core/campaigns/services/campaigns.service';
import { IComment } from 'src/app/core/comments/models/comment.model';
import { IUser } from 'src/app/core/users/models/user.model';
import { UserService } from 'src/app/core/users/services/user.service';
import { PaypalBtnComponent } from 'src/app/shared/components/buttons/paypal-btn/paypal-btn.component';
import { PayuBtnComponent } from 'src/app/shared/components/buttons/payu-btn/payu-btn.component';
import { WompiBtnComponent } from 'src/app/shared/components/buttons/wompi-btn/wompi-btn.component';
import { CountriesService } from 'src/app/shared/services/countries.service';
// ES6 Modules or TypeScript
import Swal from 'sweetalert2'

import colombia from '../../../assets/countries/colombia';

@Component({
  selector: 'ui-checkout-page',
  templateUrl: './checkout-page.component.html',
  styleUrls: ['./checkout-page.component.less'],
})
export class CheckoutPageComponent implements OnInit {
  checkFormP = new UntypedFormGroup({
    nombresito: new UntypedFormControl('', Validators.required),
  });

  public campaigns$: Observable<ICampaign[]>;
  public campaign: ICampaign;

  public isModal = false;
  public isModalSuccess = false;

  public amount = 0;
  public currency = 'COP';
  public userType = 'person';

  public companyName = '';
  public userName = '';
  public cc = '';
  public cc_type = 'CC';
  public email = '';
  public postCode = '';

  public cities = [];
  public cc_types = ['CC', 'TI', 'CE'];
  public countries = [];
  public departments = [];

  public country: any = null;
  public department: any = null;
  public city: any = null;

  public phone = '';
  public ondepartment = false;

  public user: IUser | any = null;

  public payuSettings: any = {};

  public payBtnSelected = '';

  public errors = [];
  public donationButtonActive = '';

  @ViewChild(PayuBtnComponent) payuBtnComponent: PayuBtnComponent;
  @ViewChild(PaypalBtnComponent) paypalBtnComponent: PaypalBtnComponent;
  @ViewChild(WompiBtnComponent) wompiBtnComponent: WompiBtnComponent;

  constructor(
    private route: ActivatedRoute, 
    private $user: UserService, 
    private $campaigns: CampaignsService, 
    private toastr: ToastrService,
    private $countries: CountriesService) {
    this.user = {};
    this.user.firstname = '';
    this.user.lastname = '';
    
  }

  async ngOnInit() {
    const campaignId = this.route.snapshot.paramMap.get('id');
    this.campaigns$ = this.$campaigns.syncCampaigns();
    this.campaigns$.subscribe(async (campaigns: ICampaign[]) => {
      if (campaigns && campaigns.length > 0) {
        const campaign = campaigns.find((_campaign) => parseInt(_campaign.id + '', 10) === parseInt(campaignId, 10));
        this.campaign = campaign;

        if (!this.campaign.stats) {
          this.campaign.stats = {
            total: 0,
            last24hrs: 0,
          };
        }
        this.payuSettings.campaign = this.campaign;
      } else {
        await this.$campaigns.fetchCampaignsById(parseInt(campaignId, 10));
      }
    });

    this.$user.syncUser().subscribe((user) => {
      this.payuSettings.user = user;
          
      this.setUserName(user.name);
 

    });


    // Set Default Values from User DB
    this.userType = 'person';
    this.user = this.$user.getUser() || {};

   
    if(!(this.user && this.user.name)) {
      this.userType = 'person';
    } else {
      this.userType = (this.user as any) && (this.user as any).rol === 'USER' ? 'person' : 'company';
    }

    this.userName = (this.user as any) && this.user.name ? this.user.name : '';
    this.companyName = (this.user ? this.user.company : '') || '';
    this.cc = (this.user ? this.user.nid_number : '') || '';
    this.cc_type = (this.user ? this.user.nid_type : 'CC') || 'CC';
    this.email = (this.user ? this.user.email : '') || '';
    this.phone = (this.user ? this.user.phone : '') || '';

    // Default PayU Settings
    if (this.user) {
      this.payuSettings.company = (this.user ? this.user.company : '') || '';
      this.payuSettings.userName = (this.user ? this.user.name : '') || '';
      this.payuSettings.cc = this.user.nid_type + this.user.nid_number || '';
      this.payuSettings.city = this.user.city || '';
      this.payuSettings.email = this.user.email || '';
    }

    if (this.user) {
      this.setCurrency('COP');
    }
  }

 public setPlace(place: {city: string; country:string; department: string}) {
    this.country = place.country;
    this.department = place.department;
    this.city = place.city;
    (this.user as IUser).country = place.country;
    (this.user as IUser).department = place.department;
    (this.user as IUser).city = place.city;

 }

  public setUserFirstName($text) {
    this.user.firstname = $text;
  }
  public setUserLastName($text) {
    this.user.lastname = $text;
  }
  get firstname() {
    return this.user ? this.user.firstname : '';
  }
  get lastname() {
    return this.user ? this.user.lastname : '';
  } // return this.splitStringIntoWords(this.userName,1) }
  public splitStringIntoWords(str, index) {
    const s = str;
    let middle = Math.floor(s.length / 2);
    const before = s.lastIndexOf(' ', middle);
    const after = s.indexOf(' ', middle + 1);
    if (middle - before < after - middle) {
      middle = before;
    } else {
      middle = after;
    }
    const s1 = s.substr(0, middle);
    const s2 = s.substr(middle + 1);
    const sn = [s1, s2];
    if (index || index === 0) {
      return sn[index];
    } else {
      return str;
    }
  }
  
  getFirstDonationButton() {
    const options = {
      COP: '10000',
      USD: '5',
      EUR: '5',
    };
    return options[this.currency] || '10000';
  }
  getSecondDonationButton() {
    const options = {
      COP: '15000',
      USD: '10',
      EUR: '10',
    };
    return options[this.currency] || '15000';
  }
  getThirdDonationButton() {
    const options = {
      COP: '20000',
      USD: '15',
      EUR: '15',
    };
    return options[this.currency] || '20000';
  }
  onPayUSelect() {
    this.payBtnSelected = this.payBtnSelected !== 'PAYU' ? 'PAYU' : '';
  }
  onPayPalSelect() {
    this.payBtnSelected = this.payBtnSelected !== 'PAYPAL' ? 'PAYPAL' : '';
  }
  onWompiSelect() {
    this.doSubmitPay()
    this.payBtnSelected = this.payBtnSelected !== 'WOMPI' ? 'WOMPI' : '';
  }
  closeModal() {
    this.isModal = false;
    this.isModalSuccess = false;
  }
  openModal() {
    this.isModal = true;
  }

  setAmout(amount, type = 'button-input') {

    if (type === 'button-input') {
      const currency = this.currency;
      if (currency === 'COP') {
        if (amount === 10000) {
          this.donationButtonActive = '10000';
          this.setValueAmount(10000);
        }
        if (amount === 15000) {
          this.donationButtonActive = '15000';
          this.setValueAmount(15000);
        }
        if (amount === 20000) {
          this.donationButtonActive = '20000';
          this.setValueAmount(20000);
        }
      }

      if (currency === 'USD') {
        if (amount === 10000) {
          this.donationButtonActive = '10000';
          this.setValueAmount(5);
        }
        if (amount === 15000) {
          this.donationButtonActive = '15000';
          this.setValueAmount(10);
        }
        if (amount === 20000) {
          this.donationButtonActive = '20000';
          this.setValueAmount(15);
        }
      }
      if (currency === 'EUR') {
        if (amount === 10000) {
          this.donationButtonActive = '10000';
          this.setValueAmount(5);
        }
        if (amount === 15000) {
          this.donationButtonActive = '15000';
          this.setValueAmount(10);
        }
        if (amount === 20000) {
          this.donationButtonActive = '20000';
          this.setValueAmount(15);
        }
      }
    } else {

      const _amount = parseFloat((amount + '').replace(/[^\d.-]/g, ''));
      if (!Number.isNaN(_amount)) {
        this.setValueAmount(_amount);
      } else {
        this.setValueAmount(_amount);
      }

      this.donationButtonActive = amount.replace(/\D/g,'');
      this.payuSettings.amount = amount;

    }
  }

  setValueAmount(value: number){

    if(this.currency === 'COP'){
      if(value > 9900) this.amount = value;
      else this.toastr.error('Error Donación', 'Cantidad minima 10000 COP');
    }
    if(this.currency === 'USD'){
      if(value > 4.9) this.amount = value;
      else this.toastr.error('Error Donación', 'Cantidad minima 5 USD');
    }
    if(this.currency === 'EUR'){
      if(value > 4.9) this.amount = value;
      else this.toastr.error('Error Donación', 'Cantidad minima 5 USD');
    }
    
  }

  setCurrency(currency) {
    this.currency = currency;
    this.payuSettings.currency = currency;
  }
  setCompanyName($event) {
    this.companyName = $event;
    this.payuSettings.company = this.companyName;
  }
  setUserName($event) {
    this.userName = $event;
    this.payuSettings.userName = this.userName;
  }
  setCC($event) {
    this.cc = $event;
    this.payuSettings.cc = this.cc;
    this.user.nid_number = this.cc;
  }
  setCCType($event) {
    this.cc_type = $event;
    this.payuSettings.cc_type = this.cc_type;
    this.user.nid_type =  this.cc_type;
  }

  setEmail($event) {
    this.email = $event;
    this.user.email = $event;
    this.payuSettings.email = this.email;
    this.payuSettings.campaign = this.campaign;
  }
  setPostCode($event) {
    this.postCode = $event;
    this.payuSettings.postCode = this.postCode;
  }
  setPhoneNumber($event) {
    this.phone = $event;
    this.user.phone = $event;
    this.payuSettings.phone = $event;
  }

  public onPayUSucced(response: any) {}
  public getErrorByFieldName(fieldname) {
    return this.errors.find((err) => err.field === fieldname)?.error;
  }
  public checkForm() {
    const errors = [];

    if (!this.user.firstname) {
      errors.push({ field: 'firstname', error: 'Nombre invalido' });
    }

    if (!this.user.lastname) {
      errors.push({ field: 'lastname', error: 'Apellido invalido' });
    }

    // if(!this.companyName) errors.push({ field: 'companyName', error: 'Empresa invalida'});
    if (!this.city) {
      errors.push({ field: 'city', error: 'Ciudad Invalida' });
    }
    if (!this.cc) {
      errors.push({ field: 'cc', error: 'Cedula Invalida' });
    }
    if (!this.currency) {
      errors.push({ field: 'currency', error: 'Moneda Invalida' });
    }
    if (!this.amount) {
      errors.push({ field: 'amount', error: 'Monto Invalido' });
    }
    if (!this.email) {
      errors.push({ field: 'email', error: 'Email Invalido' });
    }

    if (!this.phone) {
      errors.push({ field: 'phone', error: 'Telefono Invalido' });
    }
    if (!this.postCode) {
      //errors.push({ field: 'postcode', error: 'Codigo Postal Invalido' });
    }

    this.errors = errors;
    
    if(this.errors && this.errors.length > 0){
      window.scrollTo(0, 0);
      Swal.fire({
        title: 'Error!',
        text: 'Revisa si te falta algun campo por llenar correctamente',
        icon: 'error',
        confirmButtonText: 'Ok'
      })
    }

    return errors;
  }


  public async onEpayCoSelect(){
    if(this.amount > 1){
      this.onWompiSelect();
    } else {
      this.toastr.error('DONACIÓN ERROR', 'El monto de la donación debe ser mayor a 0');
    }
    
  }

  public async doSubmitPay() {
    const errors = this.checkForm();
    if (!errors || errors.length === 0) {
      if (this.payBtnSelected === 'PAYU') {
        // this.payuBtnComponent.onSubmit();
      }

      if (this.payBtnSelected === 'PAYPAL') {
        // this.paypalBtnComponent.onSubmit();
      }

      if (this.payBtnSelected === 'WOMPI') {
      
        console.log(' ')
        console.log(' doSubmitPay :: user ')
        console.log(this.user)
        console.log(' ')
        console.log(' ')

        this.isModalSuccess = true;
        const campaignId = this.campaign.id + '';
        await this.wompiBtnComponent.onSubmit();
        await this.$campaigns.fetchCampaignsById(parseInt(campaignId, 10));
        this.isModalSuccess = false;

      }
    }
  }
}
