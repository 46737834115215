import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { trigger, style, animate, transition } from '@angular/animations';

import {
  SwiperComponent,
  SwiperDirective,
  SwiperConfigInterface,
  SwiperScrollbarInterface,
  SwiperPaginationInterface,
  SwiperAutoplayInterface,
} from 'ngx-swiper-wrapper';
import { ICampaign } from 'src/app/core/campaigns/models/campaign.model';
import { Router } from '@angular/router';
import EditorJS from '@editorjs/editorjs';
import Header from '@editorjs/header';
import List from '@editorjs/list';
import Link from '@editorjs/link';



@Component({
  selector: 'ui-home-cover',
  templateUrl: './home-cover.component.html',
  styleUrls: ['./home-cover.component.less'],
  animations: [
    trigger('enterAnimation', [
      transition(':enter', [style({ opacity: 0 }), animate('500ms', style({ opacity: 1 }))]),
      transition(':leave', [style({ opacity: 1 }), animate('500ms', style({ opacity: 0 }))]),
    ]),
  ],
})
export class HomeCoverComponent implements OnInit {
  @Input()
  campaigns: ICampaign[];

  readonly: any;

  public show = true;
  public container = '';

  constructor(private router: Router) {}

  public slides = [
    {
      bg: '/assets/mocks/p1.png',
      title: 'Titular de la campaña o causa a promover',
      description: 'Explicación breve sobre lo que se esta haciendo se necesita para brindar apoyo.',
      link: '/campaign/mock1',
    },
    {
      bg: '/assets/mocks/p2.png',
      title: 'Titular de la campaña o causa a promover',
      description: 'Explicación breve sobre lo que se esta haciendo se necesita para brindar apoyo.',
      link: '/campaign/mock2',
    },
    {
      bg: '/assets/mocks/p3.png',
      title: 'Titular de la campaña o causa a promover',
      description: 'Explicación breve sobre lo que se esta haciendo se necesita para brindar apoyo.',
      link: '/campaign/mock3',
    },
  ];

  public type = 'component';

  public disabled = false;

  public config: SwiperConfigInterface = {
    direction: 'horizontal',
    slidesPerView: 1,
    scrollbar: false,
    navigation: false,
    pagination: true,
    autoplay: false,
    effect: 'fade',
    speed: 10,
    loop: true,
  };

  private autoplay: SwiperAutoplayInterface = {
    delay: 1500,
  };

  private scrollbar: SwiperScrollbarInterface = {
    el: '.swiper-scrollbar',
    hide: false,
    draggable: true,
  };

  private pagination: SwiperPaginationInterface = {
    el: '.swiper-pagination',
    clickable: true,
    hideOnClick: false,
  };

  @ViewChild(SwiperComponent, { static: false }) componentRef?: SwiperComponent;
  @ViewChild(SwiperDirective, { static: false }) directiveRef?: SwiperDirective;

  ngOnInit(): void {
    this.config.pagination = this.pagination;
  }

  public toggleType(): void {
    this.type = this.type === 'component' ? 'directive' : 'component';
  }

  public toggleDisabled(): void {
    this.disabled = !this.disabled;
  }

  public toggleDirection(): void {
    this.config.direction = this.config.direction === 'horizontal' ? 'vertical' : 'horizontal';
  }

  public toggleSlidesPerView(): void {
    if (this.config.slidesPerView !== 1) {
      this.config.slidesPerView = 1;
    } else {
      this.config.slidesPerView = 2;
    }
  }
  public goToCampaign(campaign) {
    this.router.navigateByUrl(this.getLink(campaign));
  }
  public getLink(campaign: ICampaign): string {
    return '/campaing/' + campaign.id;
  }

  public toggleKeyboardControl(): void {
    this.config.keyboard = !this.config.keyboard;
  }

  public toggleMouseWheelControl(): void {
    this.config.mousewheel = !this.config.mousewheel;
  }

  public onIndexChange(index: number): void {
    console.log('Swiper index: ', index);
  }

  public onSwiperEvent(event: string): void {
    console.log('Swiper event: ', event);
  }

  public setCity(city: string) {}
  public setSearchByCampaignType(category: string) {}

  public setContainer(containerName: string) {
    this.container = this.container ? '' : containerName;
  }
}
