import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { ColumnMode } from '@swimlane/ngx-datatable';
import { API, APIDefinition, Columns, Config, DefaultConfig } from 'ngx-easy-table';
import { ICampaign } from 'src/app/core/campaigns/models/campaign.model';
import { CampaignsService } from 'src/app/core/campaigns/services/campaigns.service';
import { ProfilePageService } from 'src/app/pages/profile-page/profile-page.service';
import { IUser } from 'src/app/core/users/models/user.model';
import { Router } from '@angular/router';
import { IStatusOption, IStatusValue } from 'src/app/core/campaigns/models/status.model';
import Swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';
import { UserService } from 'src/app/core/users/services/user.service';

@Component({
  selector: 'ui-admin-users-view',
  templateUrl: './admin-users-view.component.html',
  styleUrls: ['./admin-users-view.component.less']
})
export class AdminUsersViewComponent {

  checked = false;

  @ViewChild('table') _table: APIDefinition;
  @ViewChild('statusTpl', { static: true }) statusTpl: TemplateRef<any>;
  @ViewChild('actionTpl', { static: true }) actionTpl: TemplateRef<any>;


  constructor(
    private toastr: ToastrService, 
    private $user: UserService,
    private $campaigns: CampaignsService,
    private $profile: ProfilePageService, 
    private router: Router) {}

  public get table() {
    if (this.campaigns.length > 0) {
      this.data = this.campaigns;
    }
    return this.campaigns;
  }

  campaigns: ICampaign[];

  filter: any;

  user: IUser | any = {};

  editRow: number;
  campaign: ICampaign | any;

  public data = [];
  public columns: Columns[];
  public configuration: Config;
  public tab = 'admin_campaigns';

  public sidebarVisible = false;
  public selectedUser: IUser = null;

  expanded = {};
  timeout: any;

  ColumnMode = ColumnMode;

  modal = false;
  selected;


  public users:IUser[] = [];

  
  public async ngOnInit() {

    // Intial Setup
    this.doLoadTable();
    const users = await this.$user.getAll();
    this.users = users;

    this.$user.syncUsers().subscribe((users) => {
      this.users = users;
      this.updateNxTable();
    })

  }

  updateNxTable() {

    if (this.users && this.users.length > 0) {
     
      const filtered = JSON.parse(JSON.stringify(this.users) + '');
      console.log(' filtered ')
      console.log(filtered)

      this.data = filtered.map((user: IUser) => {
        return {
          "id":  user.id,
          "name": user.name,
          
          "email": user.email,
          "photo_url": user.photo_url,

          "rol": user.rol, // Dropdown
          "status": user.status,
          "company": user.company,
          "position": user.position,
          
          "total_organized": 0, // Total de numero de campañas organizadas
          "total_collected":  0, // Total recaudado de las empresas que el organizado
          "last_campaign_organized": 0,
          // "last_campaign_organized": user.position,

        };
      });
    }
  }

  setTab(tabName) {
    this.tab = tabName;
  }
  doLoadTable() {
    this.configuration = { ...DefaultConfig };
    this.configuration.selectRow = true;
    // ... etc.
    this.columns = [
      { key: 'id', title: '#ID', width: '2.5%', placeholder: ' ' , orderBy: 'desc'},
      { key: 'name', title: 'Name', placeholder: ' ' },
      { key: 'rol', title: 'Rol', placeholder: ' ' },
      { key: 'email', title: 'Email', placeholder: ' ' },
      { key: 'company', title: 'Company', placeholder: ' ' },
      { key: 'status', title: 'Stats', placeholder: ' ' },

    ];
  }
  edit(rowIndex: number): void {
    this.editRow = rowIndex;
  }

  onEvent(event: { event: string; value: any }): void {
    this.selected = JSON.stringify(event.value.row, null, 2);
    if(event.event === 'onClick') {
      const id = JSON.parse(this.selected).id;
      this.$user.getUserById(id).then(user => {
        this.selectedUser = user;
        this.sidebarVisible = true;
      })
    }

  }

  onChange(event: Event): void {
    this._table.apiEvent({
      type: API.onGlobalSearch,
      value: (event.target as HTMLInputElement).value,
    });
  }

  // USER::PICTURES
  doRemovePicture(){}
  doEditPicture(){}

  // USER::OnChanges
  onChangeType(event: string){
    this.selectedUser.user_type = event;
  }
  onChangeRol(event: string){
    this.selectedUser.rol = event;
  }
  onChangeStatus(event: string){
    this.selectedUser.status = event;
  }
  onChangeName(event: string){
    this.selectedUser.name = event;
  }
  onChangeEmail(event: string){
    this.selectedUser.email = event;
  }
  onChangePhone(event: string){
    this.selectedUser.phone = event;
  }

  // USER::EDIT
  async doUserSave(){
    const user = JSON.parse(JSON.stringify(this.selectedUser))
    console.warn(user)
    try{
      const response = await this.$user.patchUserById(user.id, user);
      this.toastr.success('Todo Ok');
    } catch(err){
      this.toastr.error('ERROR')
    }
    
  }
}
