<div class="campaing map">
  <div class="row x-end y-center">
    <h1 class="map-title">Ubicación del proyecto</h1>
  </div>
  <div class="row">
    <!--
    <div class="map-gmap" *ngIf="place && place.lat && place.lon">
      <div class="wrapper">

        <agm-map [latitude]="latitude" [longitude]="longitude">
          <agm-marker [markerDraggable]="editable" [latitude]="latitude" [longitude]="longitude" (dragEnd)="markerDragEnd($event)"></agm-marker>
        </agm-map>

      </div>
    </div>
    -->
    <div class="map-place" *ngIf="place_city">
      <h2>{{ place_city }}</h2>
    </div>
  </div>
</div>
