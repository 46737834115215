import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgProgressComponent } from 'ngx-progressbar';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { ICampaign } from 'src/app/core/campaigns/models/campaign.model';
import { CampaignsService } from 'src/app/core/campaigns/services/campaigns.service';
import { IComment } from 'src/app/core/comments/models/comment.model';
import { IUser } from 'src/app/core/users/models/user.model';
import { UserService } from 'src/app/core/users/services/user.service';
import { PicturesService } from 'src/app/shared/services/pictures.service';
import Swal from 'sweetalert2';
import { FilterByIsActivePipe } from '../home-page/pipes/filterByIsActive.pipe';
import { CampaignDescriptionComponent } from 'src/app/core/campaigns/components/campaign-description/campaign-description.component';
import { resolve } from 'cypress/types/bluebird';

@Component({
  selector: 'app-campaign-page',
  templateUrl: './campaign-page.component.html',
  styleUrls: ['./campaign-page.component.less'],
})
export class CampaignPageComponent implements OnInit {

  @ViewChild(NgProgressComponent) progressBar: NgProgressComponent;
  @ViewChild(CampaignDescriptionComponent) campaignDescription: CampaignDescriptionComponent;

  public campaigns$: Observable<ICampaign[]>;
  public campaign: ICampaign;

  public idA = 0;
  public categoryA: string;

  public isModal = false;
  public statusModal = '';
  public displayModal: boolean;

  public shareURL = '';
  public user: IUser | null = null;

  public status = '';
  public photos: Array<{src: string; file: any;}> = [];

  public isPreview = false;
  public campaignJSON = '';

  constructor(
    private $http: HttpClient,
    private route: ActivatedRoute,
    private router: Router,
    private $pictures: PicturesService,
    private $user: UserService,
    private $campaigns: CampaignsService,
    private toastr: ToastrService,
    private isActivePipe: FilterByIsActivePipe
  ) {}



  async ngOnInit() {
    const campaignId = this.route.snapshot.paramMap.get('id');
    const statusPayCo = this.route.snapshot.paramMap.get('status');
    if(statusPayCo){
      this.status = statusPayCo;
      this.showModalDialog();
      console.warn('OPEN CLOSE MODAL')

      setTimeout(() =>  {
        console.error('CLOSING CLOSE MODAL')
        this.closeModal()
      }, 5000)
    }


    this.campaigns$ = this.$campaigns.syncCampaigns();
    this.campaigns$.subscribe(async (campaigns: ICampaign[]) => {
      if (campaigns && campaigns.length > 0) {
        const campaign = campaigns.find((_campaign) => parseInt(_campaign.id + '', 10) === parseInt(campaignId, 10));
        const camapaignId = campaign.id;
        campaign.comments = (await this.$campaigns.getCommentsByCampaingId(campaign.id)) as IComment[];
        campaign.donors = typeof campaign.donors === 'string' || campaign.donors instanceof String ? JSON.parse(campaign.donors + '') : campaign.donors;
        campaign.stats = typeof campaign.stats === 'string' || campaign.stats instanceof String ? JSON.parse(campaign.stats + '') : campaign.stats;

        this.categoryA = campaign.category;
        this.idA = campaign.id;
        this.campaign = campaign;
        this.campaign.organizer.photo_url =
          campaign.organizer.photo_url || 'https://ui-avatars.com/api/?background=random&name=' + encodeURI((campaign.organizer as any).name);
        this.shareURL = 'http://soliun.com/campaing/' + campaign.id;

        if (!this.campaign.stats) {
          this.campaign.stats = {
            total: 0,
            last24hrs: 0,
          };
        }

        this.photos = this.campaign.photos.map( item => { return { src: item, file: null}})

        this.campaignJSON = this.campaign.description;
      } else {
        await this.$campaigns.fetchCampaignsById(parseInt(campaignId, 10));
      }
    });

    this.$user.syncUser().subscribe((user) => {
      if (user) {
        this.user = user;
      }
    });
  }

  public get isActive(){
    if(!this.campaign) return false;
    if(this.isAdmin) return true;
    if(this.isOrganizer) return true;
    if(this.isPreview) return true;
    if(this.campaign && this.campaign.status === 'S3_PUBLISHED') return true;
    return this.isActivePipe.transform([this.campaign]).length === 1 ;
  }
  public get isAdmin(){
    return this.user && ['wallamejorge@hotmail.com','liudezain@gmail.com','civil.delgado@hotmail.com'].includes(this.user.email)
  }

  showModalDialog() {
    this.displayModal = true;
  }


  updateInfo($event) {
    if($event.title) this.campaign.title = $event.title;
    if($event.end) this.campaign.date_end = $event.end;
    if($event.goal) this.campaign.goal = $event.goal;
  }

  updateDescription($event) {
    this.campaign.description = $event;
  }

  updatePictures($event: Array<{src: string; file: any;}>) {
    this.campaign.photos = $event.map( photo => photo.src);
    this.photos = $event;
    console.error('updatePictures', this.photos)
  }

  deletePicture($photo_src: string) {
    const photos = JSON.parse(JSON.stringify(this.campaign.photos));
    const photoIndex = photos.findIndex((photo) => photo === $photo_src);
    photos[photoIndex] = '';
    this.campaign.photos = photos;
  }

  updateLatLng($event) {
    this.campaign.place.lat = $event.lat;
    this.campaign.place.lon = $event.lng;
  }

  doDonate() {
    console.error(' >>> ');
    console.error(' >>> >>> do donate https://kkl.exe.mybluehost.me');
    console.error(' >>> ');
    this.router.navigateByUrl('/checkout/' + this.route.snapshot.paramMap.get('id'));
  }
  closeModal() {
    this.isModal = false;
    this.displayModal = false;
    console.log(' closeModal() ');
    console.log(this.isModal)
  }
  openModal() {
    this.isModal = true;
  }

  goToDonate() {
    this.router.navigateByUrl('/checkout/' + this.route.snapshot.paramMap.get('id'));
  }

  async doSave2() {
    this.campaignDescription.onSave().then(response => {
      console.log('save', JSON.stringify(response))
    })
  }
  async doSave() {
    const campaing: any = JSON.parse(JSON.stringify(this.campaign) + '');

    const json = await this.campaignDescription.onSave();
    const description = JSON.stringify(json);
    campaing.description = description;

    campaing.photo_1 = this.campaign.photos[0].replace('','') || '';
    campaing.photo_2 = this.campaign.photos[1].replace('','') || '';
    campaing.photo_3 = this.campaign.photos[2].replace('','') || '';
    campaing.photo_4 = this.campaign.photos[3].replace('','') || '';

    if(this.isOrganizer && campaing.status === 'S0_IN_PROCESS'){
      campaing.status = 'S1_IN_PENDING';
    }

    const photos = this.photos;

    for (let k = 0; k < photos.length; k++) {
      if (photos[k] && photos[k].file) {
        try {
          const url = await this.$pictures.upload(photos[k].file);
          this.campaign.photos[k] = url;
          campaing['photo_' + (k + 1)] = url;
        } catch (e) {
          console.error(' ');
          console.error(' ERROR UPLOAAD PICTURE ');
          console.error(e);
          console.error(' ');
        }
      }
      if(photos[k] && photos[k] && !(photos[k].src.includes('http') || photos[k].src.includes('base64'))){
        campaing['photo_' + (k + 1)] = '';
      }
    }

    try {
      const pachedCampaing = await this.$campaigns.patch(this.campaign.id, campaing);
      this.toastr.success('Guardando', 'Campaña guardada exitosamente');
    } catch (e) {
      console.error(' ');
      console.error(' ERROR PATCHING CAMPAING ');
      console.error(e);
      console.error(' ');
      this.toastr. error('Guardando', 'Campaña guardada fallo');
    }

  }

  doPreview() {
    const user = JSON.parse(JSON.stringify(this.user) + '');
    this.user = null;
    this.isPreview = true;
    this.progressBar.start();
  
    setTimeout(() => {
      this.user = user;
      this.isPreview = false;
      this.progressBar.complete();
    }, 5000);
  }

  public get isOrganizer(): boolean {
    return (this.isAdmin) || (
      this.user &&
      this.campaign &&
      this.campaign.organizer &&
      (
        this.campaign.status == 'S0_IN_PROCESS' ||
        this.campaign.status == 'S2_IN_CORRECTING'
      ) &&
      this.user.id === this.campaign.organizer.id  ||
      (this.user && this.user?.email === 'wallamejorge@hotmail.com')
    );
  }
}
