<div class="dropdown-input">
  <div class="input-wrapper">
    <div class="input-border">

      <ng-container *ngIf="loading">
          <img src="../../../../../../assets/icons/loading-line.gif" width="50px" style="
          margin: 0 auto;
          display: block;
          ">
      </ng-container>

      <ng-container *ngIf="!loading">
        <span class="input-label">{{ label }}</span>
        <select (change)="setOption($event)" [value]="default" *ngIf="!option">
          <option *ngFor="let item of options"
          [value]="item"
          [selected]="item === default">
            <div *ngIf="i18">
             {{ ('campaings.categories.' +item.toUpperCase())  | translate }}
            </div>
            <div *ngIf="!i18">
              {{ item }}
            </div>
          </option>
        </select>
        <select (change)="setOption($event)" [value]="default" *ngIf="option">
          <option *ngFor="let item of options"
            [value]="item[option]"
            [selected]="item[option] === default[option]">
            <div *ngIf="i18">
              {{ item[option] |  translate}}
            </div>
            <div *ngIf="!i18">
              {{ item[option] }}
            </div>
          </option>
        </select>
      </ng-container>

    </div>
  </div>
</div>
