import { Component, OnInit } from '@angular/core';
import { IServerCampaign } from 'src/app/core/campaigns/models/campaign.model';
import * as moment from 'moment';
import { UserService } from 'src/app/core/users/services/user.service';
import { SignupService } from 'src/app/auth/services/signup.service';
import { CampaignsService } from 'src/app/core/campaigns/services/campaigns.service';
import { ActivatedRoute, Router } from '@angular/router';
import { IStatusValue } from 'src/app/core/campaigns/models/status.model';
import Swal from 'sweetalert2';

@Component({
  selector: 'ui-create-campaign-cause-page',
  templateUrl: './create-campaign-cause-page.component.html',
  styleUrls: ['./create-campaign-cause-page.component.less'],
})
export class CreateCampaignCausePageComponent implements OnInit {
  public companyName = '';
  public companyError = '';

  public phone = '';
  public phoneError = '';

  public mobile = '';
  public mobileError = '';

  public nit = '';
  public nitError = '';

  public email = '';
  public emailError = '';

  public password = '';
  public passwordError = '';

  public foundationName = '';
  public foundationNameError = '';

  public foundationPostCode = '';
  public foundationPostCodeError = '';

  public foundationDescription = '';
  public foundationDescriptionError = '';

  public foundationIsUrgent = '';
  public foundationIsUrgentError = '';

  public foundationCategory = '';
  public foundationCategoryError = '';

  public foundationGoal = '';
  public foundationGoalError = '';

  public foundationDeadline = '';
  public foundationDeadlineError = '';

  public foundationCurrency = 'COP';
  public foundationCurrencyError = '';

  public isLogged = false;
  public user = null;

  public optionsCategories = [
    'OLDER_ADULTS',
    'ANIMALS',
    'EDUCATION',
    'YOUNG_PEOPLE',
    'FAMILIES',
    'ENVIRONMENTAL',
    'KIDS'
  ];

  public fields = {
    'company': {
      value: '',
      label: 'Nombre de la Fundación',
      type: 'text',
      border: true,
      error: {
        value: (value): boolean => {
          return !((value || '').length > 0);
        },
        text: 'N/A'
      }
    },
    'mobile': {
      value: '',
      label: 'Numero Celular',
      type: 'text',
      border: true,
      error: {
        value: (value): boolean => {
          const regHexPhone =/^(\+{0,})(\d{0,})([(]{1}\d{1,3}[)]{0,}){0,}(\s?\d+|\+\d{2,3}\s{1}\d+|\d+){1}[\s|-]?\d+([\s|-]?\d+){1,2}(\s){0,}$/gm;
          return  !(regHexPhone.test(value))
        }
        , text: 'Numero del Celular Invalido'
      }
    },
    'phone': {
      value: '',
      label: 'Numero Telefono',
      type: 'text',
      border: true,
      error: {
        value: (value): boolean => {
          const regHexPhone =/^(\+{0,})(\d{0,})([(]{1}\d{1,3}[)]{0,}){0,}(\s?\d+|\+\d{2,3}\s{1}\d+|\d+){1}[\s|-]?\d+([\s|-]?\d+){1,2}(\s){0,}$/gm;
          return !(regHexPhone.test(value))
        },
        text: 'Numero de Teléfono Invalido'
      }
    },
    'nid_number': {
      value: '',
      label: 'NIT',
      type: 'number',
      border: true,
      error: {
        value: (value): boolean => {
          return !((value || '').length > 0);
        },
        text: 'N/A'
      }
    },
    'email': {
      value: '',
      label: 'Email',
      type: 'email',
      border: true,
      error: {
        value: (value): boolean => {
          return !((value || '').length > 0);
        },
        text: 'N/A'
      }
    },
    'password': {
      value: '',
      label: 'Contraseña',
      type: 'password',
      border: true,
      error: {
        value: (value): boolean => {
          return !((value || '').length > 0);
        },
        text: 'N/A'
      }
    },
    'password2': {
      value: '',
      label: 'Contraseña',
      type: 'password',
      border: true,
      error: {
        value: (value): boolean => {
          return !((value || '').length > 0);
        },
        text: 'N/A'
      }
    },
    'name': {
      value: '',
      label: 'Titulo de Proyecto',
      type: 'text',
      border: true,
      error: {
        value: (): boolean => {
          return !(this.fields['name'].value.length > 0);
        },
        text: 'N/A'
      }
    },
    'postcode': {
      value: '',
      label: 'Código postal (Opcional)',
      type: 'text',
      border: true,
      error: {
        value: (): boolean => {
          return false;
        },
        text: 'N/A'
      }
    },
    'urgent': {
      value: '',
      label: 'Revisión Urgente',
      type: 'radiobutton',
      border: true,
      error: { value: false, text: 'N/A' }
    },
    'description': {
      value: '',
      label: 'Descripcion de Proyecto',
      type: 'text',
      border: true,
      error: {
        value: (value): boolean => {
          return !(value.length > 0);
        },
        text: 'N/A'
      }
    },
    'amount': {
      value: '',
      label: 'Descripcion de Proyecto',
      type: 'text',
      border: true,
      error: {
        value: (): boolean => {
          return !(this.fields['amount'].value.length > 0);
        },
        text: 'N/A'
      }
    },
    'category': {
      value: '',
      label: 'Categoria',
      type: 'options',
      border: true,
      error: {

        value: (): boolean => {
          return !(this.fields['category'].value.length > 0);
        },
        text: 'N/A'
      }
    },
    'place': {
      value: {
        country: '',
        deparment: '',
        city: ''
      },
      label: 'Place',
      type: 'options',
      border: true,
      error: {
        value: (): boolean => {
          return !(
            this.fields['place'].value.country.length > 0 &&
            this.fields['place'].value.deparment.length > 0 &&
            this.fields['place'].value.city.length > 0
          );
        },
        text: 'N/A'
      }
    },
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private $user: UserService,
    private signupService: SignupService,
    private campaignService: CampaignsService
  ) { }

  ngOnInit(): void {
    this.$user.syncUser().subscribe((user) => {
      this.user = user;
      console.warn(' ');
      console.warn(' this.user ');
      console.warn(this.user);
      console.warn(' ');
    });
  }


  setField(fieldName, fieldValue) {
    if (this.fields[fieldName].type === 'text') {
      this.fields[fieldName].value = fieldValue.target.value
    }
    if (this.fields[fieldName].type === 'textarea') {
      this.fields[fieldName].value = fieldValue.target.value
    }
    if (this.fields[fieldName].type === 'options') {
      this.fields[fieldName].value = fieldValue;
    }
  }

  getErrorField(fieldName) {
    return this.fields[fieldName].error.value;
  }
  getErrorFieldText(fieldName) {
    return this.fields[fieldName].error.text;
  }
  getFieldBorder(fieldName) {
    return this.fields[fieldName].border;
  }
  getFieldLabel(fieldName) {
    return this.fields[fieldName].label;
  }
  getFieldType(fieldName) {
    return this.fields[fieldName].type;
  }


  public get getFoundationDeadline(): string {
    const out = {
      1: '1 dia',
      3: '3 dias',
      7: '1 semana',
      14: '2 semanas',
      21: '3 semanas',
      30: '1 mes',
      60: '2 meses',
      90: '3 meses',
      120: '4 meses',
    };
    return out[this.foundationDeadline] || '1 dia';
  }
  setCompanyName(text) {
    this.companyName = text;
  }
  setPhone(text) {
    this.phone = text;
  }
  setMobile(text) {
    this.mobile = text;
  }
  setCompanyNIT(text) {
    this.nit = text;
  }
  setEmail(text) {
    this.email = text;
  }
  setPassword(text) {
    this.password = text;
  }
  setFoundationName(text) {
    this.foundationName = text;
  }
  setFoundationPostCode(text) {
    this.foundationPostCode = text;
  }
  setFoundationCategory(text) {
    this.foundationCategory = text;
  }
  setFoundationDescription(text) {
    this.foundationDescription = text;
  }
  setFoundationIsUrgent(isUrgent) {
    this.foundationIsUrgent = isUrgent;
  }
  setFoundationDeadline(text) {
    this.foundationDeadline = text;
  }
  setFoundationCurrency(text) {
    this.foundationCurrency = text;
  }
  setFoundationGoal(text) {
    this.foundationGoal = text;
  }
  getDateEnd() {
    const days = parseInt(this.foundationDeadline, 10);
    const now = moment(new Date()).utc();
    const end = now.add(days, 'days');
    return end.toDate();
  }

  async addCampaingFoundation() {

    let userId;
    if (!this.user) {
      const user: any = {};
      user.name = this.fields['company'].value;
      user.email = this.fields['email'].value;
      user.password = this.fields['password'].value;
      user.phone = this.fields['phone'].value;
      user.mobile = this.fields['mobile'].value;
      const userResponse: any = await this.signupService.doSignUpWithEmailAndPassword(user);
      userId = userResponse.id;
    } else {
      userId = this.user.id;
    }

    const campaing: any = {
      organizer_id: userId,
      place_id: 1,

      title: this.fields['name'].value,
      description: this.fields['description'].value,
      type: 'FOUNDATION',
      category: this.fields['category'].value,
      status: 'S0_IN_PROCESS' as IStatusValue,
      content: this.fields['description'].value,

      photo_1: '',
      photo_2: '',
      photo_3: '',
      photo_4: '',

      place_country: this.fields['place'].value.country,
      place_department: this.fields['place'].value.deparment,
      place_city: this.fields['place'].value.city,

      share_link: ' - ',
      share_picture_url: ' - ',

      goal: parseFloat(this.foundationGoal),

      date_start: new Date(),
      date_end: this.getDateEnd(),

      summary_html: ' - ',
      summary_photo_url: ' - ',

      isInCover: false,
      isInOutstanding: false,

    };

    try{
      const campaingResponse: any = await this.campaignService.postCampaing(campaing);
      this.router.navigateByUrl(`/profile2/${userId}/campaigns/liked`);
    } catch(e){
      console.error(e);
      Swal.fire('Oops...', 'Something went wrong!', 'error');
    }
  }



}
