import { FunsForm, IFunsForm } from "../../shared/models/forms.model";
const regexPhone = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/g;

const SignUpForm = new FunsForm();

SignUpForm.title = 'SignUp Form';

SignUpForm.layout = {
    type: 'default',
    cols: [1,2,1,1]
};

SignUpForm.fields = {
    'firstname': {
        value: '',
        label: 'Nombre',
        type: 'text',
        border: true,
        required: true,
        error: {
          text: 'Error en nombre',
          check: (value) => {
            return value.length < 2;
          }
        }
    },
    'lastname': {
        value: '',
        label: 'Apellido',
        type: 'text',
        border: true,
        required: true,
        error: {
          text: 'Error en el apellido',
          check: (value) => {
            return value.length < 2;
          }
        }
    },
    'email': {
        value: '',
        label: 'Correo',
        type: 'email',
        border: true,
        required: true,
        error: {
          text: 'Error en el correo',
          check: (value) => {
            return value.length < 2 || !value.includes('@');
          }
        }
      },
    'phone': {
      value: '',
      label: 'Teléfono',
      type: 'phone',
      border: true,
      required: false,
      error: {
        text: 'Error en el teléfono',
        check: (value) => {
          return value.length < 2 || !Number(value.replace(/\s+/g, '').replace(/\+/g, ''));
        }
      }
    },
    'mobile': {
      value: '',
      label: 'Celular',
      type: 'phone',
      border: true,
      required: true,
      error: {
        text: 'Error en el celular',
        check: (value) => {
          return value.length < 2 || !Number(value.replace(/\s+/g, '').replace(/\+/g, ''));
        }
      }
    },
    'password1': {
      value: '',
      label: 'Ingresa tu Contraseña',
      type: 'password',
      border: true,
      required: true,
      error: {
        text: 'Tu contraseña debe tener al menos X (8) caracteres y una letra mayúscula.',
        check: (pass1) => {
          return pass1.length < 3;
          // return pass1.length < 8 || ((pass1 as string).toLowerCase() ===  (pass1 as string));
        }
      }
    },
    'password2': {
      value: '',
      label: 'Repite tu Contraseña',
      type: 'password',
      border: true,
      required: true,
      error: {
        text: 'La contraseña debe ser igual',
        check: (pass2, form: IFunsForm) => {
          return !(form.fields['password1'].value === pass2);
        }
      }
    },
}

export const signUpForm = SignUpForm;
