import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Params, Router } from '@angular/router';
import { SignupService } from '../../../../app/auth/services/signup.service';
import Swal from 'sweetalert2';

import { signUpForm } from 'src/app/auth/forms/signup.user.form';
import { UserService } from 'src/app/core/users/services/user.service';

@Component({
  selector: 'ui-signup-page',
  templateUrl: './signup-page.component.html',
  styleUrls: ['./signup-page.component.less'],
})
export class SignUpPageComponent implements OnInit {
  constructor(public router: Router, public userService: UserService, public route: ActivatedRoute, public signupService: SignupService) {}

  public nameError = '';
  public emailError = '';
  public phoneError = '';
  public passwordError = '';
  public authError = '';

  public type = 'user'; // user || company
  public user: any = {};
  public confirm = false;

  public signUpForm = signUpForm;

  ngOnInit(): void {
    this.route.paramMap.subscribe((params: ParamMap) => {
      // this.type = params.get('type');
    });
  }

  cmpare(index) {
    return index;
  }

  goGoogleSignUp() {}
  goFacebookSignUp() {}
  doClose() {}

  setFirstName($event) {
    this.user.firstname = $event.target.value;
    if (!this.user.firstname) {
      this.nameError = 'Nombre Invalido';
    }
  }
  setLastName($event) {
    this.user.lastname = $event.target.value;
    if (!this.user.lastname) {
      this.nameError = 'Apellido Invalido';
    }
  }
  setEmail($event) {
    this.user.email = $event.target.value;
    if (!this.user.email) {
      this.emailError = 'Correo Invalido';
    }
  }
  setMobile($event) {
    this.user.mobile = $event.target.value;
    if (!this.user.mobile && this.user.mobile.length < 10) {
      this.phoneError = 'Celular Invalido';
    }
  }
  setPassword($event) {
    this.user.password = $event.target.value;
    if (!this.user.password) {
      this.passwordError = 'Contraseña Invalida';
    }
  }

  async doConfirm() {
      this.doSignUpAsUser();
  }
  async doSignUpAsUser() {
    try {
      const user: any = {};
      user.firstname = this.signUpForm.fields['firstname'].value;
      user.lastname = this.signUpForm.fields['lastname'].value;
      user.name = this.signUpForm.fields['firstname'].value + ' ' + this.signUpForm.fields['lastname'].value;
      user.email =  this.signUpForm.fields['email'].value;
      user.password = this.signUpForm.fields['password1'].value;
      user.phone = this.signUpForm.fields['phone'].value;
      user.mobile = this.signUpForm.fields['mobile'].value;
      user.nid_number = '';
      user.nid_type = 'CC';
      user.company = user.name;
      user.position = '';
      user.city = '';
      user.address = '';
      user.rol = 'USER';
      user.description = '';
      const userResponse: any = await this.signupService.doSignUpWithEmailAndPassword(user);
      const userId = userResponse.id;
      sessionStorage.setItem('user', JSON.stringify(userResponse));
      this.userService.setUser(userResponse);

      setTimeout(() => {
        this.router.navigate(['/auth/signup/welcome']);
      }, 1000)
      //
    } catch (error) {
      Swal.fire('Error',error,'error');
    }
  }

  doSignUpAsCompany() {}

  onClickedOutside($event) {
    this.confirm = false;
  }

  onSignUpSucess(user: any) {
    const userId = user.id;
    this.router.navigate([`/profile2/${userId}/campaigns/liked`]);
  }
  onError() {}
  doNavigate() {}
}
