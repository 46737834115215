<div class="header-auth" (click)="goToLogin()" (mouseenter)="isMenuActive = true" (mouseleave)="isMenuActive = false">
  <div class="wrapper">
    <span *ngIf="!user">
      {{ 'header.login' | translate }}
    </span>
    <span *ngIf="user">
      {{ user?.email }}
    </span>
    <img src="assets/icons/profile.png" />
  </div>
  <div class="wrapper-content" [ngClass]="{ active: isMenuActive }" *ngIf="user">
    <ul>
      <li>** EDITAR PERFIL</li>
      <li>** VER MENSAJES</li>
      <li (click)="doLogout()">CERRAR SESIÓN</li>
    </ul>
  </div>
</div>
